import React, { useEffect, useRef } from 'react'
import gsap from "gsap"
import { ScrollTrigger } from 'gsap/all';
import Header from '../components/header';
function WhitePapers() {
  gsap.registerPlugin(ScrollTrigger)
  const div1Ref =useRef(null);
  const div2Ref =useRef(null);


  useEffect(()=>{

    const div1=div1Ref.current;
    const div2=div2Ref.current;

    gsap.fromTo(div1,{
      // scale:0,
      opacity:0,
      y:300,
    },{
      // scale:1,
      y:0,
      duration:1,
      opacity:1,
      ease: "power2.out"
    });

    gsap.fromTo(".cad",{
      // scale:0,
      opacity:0,
      y:100,
    },{
      stagger: 1,
      y:0,
      delay:1,
      scale:1,
      duration:2,
      opacity:1,
      ease: "power2.out"
    })

  },[])

  return (
    <div className=' h-full  gradient overflow-x-hidden'>
      <Header/>
  
    <div className='w-full container pt-12 mx-auto mt-36' >
      <section className='white-papers'>
      {/* <div className="bg-shadow"></div>
      <div className="bg-shadow-left"></div>
      <div className="bg-shadow-right"></div> */}
      <div ref={div1Ref} className=''>
          
              <h1 className='text-center text-[#bafff7] mt-5 w-full'>Workplace Knowledge Vault</h1>

      </div>
        <h2 className='text-center w-full'>COMING SOON</h2>
      
      </section>
    </div>
    <section className='h-24'>
        </section>
    </div>
  )
}

export default WhitePapers