import smartFelllows from '../../assets/ForEmployers/SectionOne/emp-smart-fellows.svg'
// import smartCircle from '../../assets/ForEmployers/SectionOne/smart-circle-text.svg'
import smartCircle from '../../assets/ForEmployers/SectionOne/section-1-ring.png'
import {gsap, Power3} from "gsap";
import {useEffect} from "react";
function SectionOne() {
  useEffect( () => {
    let tl = gsap.timeline()
    tl.from(".empSectionOneleft",{opacity: 0})
    tl.from(".empSectionOneRight",{opacity :0},"<")
    tl.to(".empSectionOneleft",{opacity :1, duration : 2,},"<")
    tl.to(".empSectionOneRight",{opacity :1, duration: 2},"<")
    tl.to(".emp-section-one-img1",{rotate: 360, duration: 30, ease: Power3.easeOut,repeat:-1,yoyo:false,delay:1},"<")

    return () => {
      tl.kill();
    }
  },[])

  return (
    <div className='hidden lg:block container mx-auto relative my-8'>
      <div className="mt-8">
        <div className="grid grid-cols-12  justify-center items-center ">
          <div className="col-span-12 lg:col-span-6 px-5 empSectionOneleft">
            <h1 className='font-light text-[#BAFFF7] mb-12'>Would you like to <span className='font-bold'>Hire</span> a <br/> <span className='font-bold text-capitalize'>Smart Fellow?</span></h1>
            <h3 className='leading-loose font-light text-[#BAFFF7]'>We transform entry level talent into smart generalists who can think, solve & communicate beyond just their technical skills. </h3>
          </div>
          <div className="col-span-12 lg:col-span-6 px-5 mt-[5%] mb-20 text-center relative bg-contain bg-no-repeat bg-fixed bg-center h-[400px] empSectionOneRight" >
            <div className='flex justify-center items-center'>
              <img src={smartFelllows} alt="" />
            </div>
            <img src={smartCircle} className='emp-section-one-img1 absolute top-[-80px] left-10  max-h-[40rem] m-auto' alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
