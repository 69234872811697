import React, {useEffect, useLayoutEffect, useRef} from 'react'
import circleText from '../../assets/ForEmployers/mobile/foremp-circle.svg'
import empCenter from '../../assets/ForEmployers/mobile/emp-center-img.svg'
import one from '../../assets/ForEmployers/mobile/01.svg'
import two from '../../assets/ForEmployers/mobile/02.svg'
import three from '../../assets/ForEmployers/mobile/03.svg'
import four from '../../assets/ForEmployers/mobile/04.svg'
import oneEmp from '../../assets/ForEmployers/mobile/1emp.svg'
import twoEmp from '../../assets/ForEmployers/mobile/2emp.svg'
import threeEmp from '../../assets/ForEmployers/mobile/3emp.svg'
import fourEmp from '../../assets/ForEmployers/mobile/4emp.svg'
import vector from '../../assets/ForEmployers/mobile/vector.svg'
import bulb from '../../assets/ForEmployers/mobile/bulb.svg'
import innerText from '../../assets/ForEmployers/mobile/inner-text.svg'
import outerText from '../../assets/ForEmployers/mobile/outer-text.svg'
//promgam images
// import program1 from '../../assets/ForEmployers/mobile/SF - Finance Mobile.png'
// import program2 from '../../assets/ForEmployers/mobile/SF - IT Mobile.png'
// import program3 from '../../assets/ForEmployers/mobile/SF - Media Mobile.png'
import program1 from '../../assets/section4/career-slide1.png'
import program2 from '../../assets/section4/career-slide2.png'
import program3 from '../../assets/section4/career-slide3.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {gsap, Power3} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ContactForm from '../For-Employers/ContactForm'
import TestimonialsCards from "../TestimonialsCards";
import smartFelllows from "../../assets/ForEmployers/SectionOne/emp-smart-fellows.svg";
// import smartCircle from "../../assets/ForEmployers/SectionOne/smart-circle-text.svg";
import smartCircle from '../../assets/ForEmployers/SectionOne/section-1-ring.png'
import financeImgMobile from "../../assets/finance-mobile.png";
import itImgMobile from "../../assets/it-mobile.png";
import mediaImgMobile from "../../assets/media-mobile.png";

gsap.registerPlugin(ScrollTrigger);

function MobileForEmp() {
  // const div1Ref = useRef(null);
  // const div2Ref = useRef(null);
  // const div3Ref = useRef(null);
  // const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null);
  const div7Ref = useRef(null);
  const div8Ref = useRef(null);
  const div9Ref = useRef(null);
  const div10Ref = useRef(null);
  const div11Ref = useRef(null);
  const div12Ref = useRef(null);
  const div13Ref = useRef(null);
  const div14Ref = useRef(null);
  const div15Ref = useRef(null);
  const div16Ref = useRef(null);
  const div17Ref = useRef(null);
  const div18Ref = useRef(null);
  const div19Ref = useRef(null);
  const div20Ref = useRef(null);
  const div21Ref = useRef(null);
  const div22Ref = useRef(null);
  const div23Ref = useRef(null);
  const div24Ref = useRef(null);
  const div25Ref = useRef(null);

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 2500,
    cssEase:"linear",
    centerMode: true,
    pauseOnHover: false,
  };


  useLayoutEffect(() => {
    let tl = gsap.timeline()
    tl.from(".empSectionOneleft",{opacity: 0})
    tl.from(".empSectionOneRight",{opacity :0},"<")
    tl.to(".empSectionOneleft",{opacity :1, duration : 2,},"<")
    tl.to(".empSectionOneRight",{opacity :1, duration: 2},"<")
    tl.to(".emp-section-one-img1",{rotate: 360, duration: 30, ease: Power3.easeOut,repeat:-1,yoyo:false,delay:1},"<")


    // Timelines 2
    let tl2 = gsap.timeline();
    // tl2.to(".emp", { rotate: 360, duration: 20, ease: "linear", repeat: -1, yoyo: false, delay: 1, }, "<")
    tl2.to(".emp-inner-circ", { rotate: 360, duration: 30, ease: "linear", repeat: -1, yoyo: false, delay: 1,}, "<")
    tl2.to(".emp-outer-circ", { rotate: -360, duration: 30, ease: "linear", repeat: -1, yoyo: false, delay: 1,}, "<");

    return () => {
      tl.kill();
      tl2.kill();
    }
  }, []);

  useEffect(() => {
    gsap.fromTo('.divPower2Out', {
      scale:0,
      opacity:0,
    }, {
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div5Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div5Ref.current,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div6Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div6Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div7Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div7Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div8Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div8Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div9Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div9Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div10Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div10Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div11Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div11Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div12Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div12Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div13Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div13Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div14Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div14Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div15Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div15Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div16Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div16Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div17Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div17Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div18Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div18Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div19Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div19Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div20Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div20Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div21Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div21Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div22Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div22Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });

    gsap.fromTo(div23Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div23Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });
    gsap.fromTo(div24Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div24Ref.current,
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });
    gsap.fromTo(div25Ref.current, {
      scale:0,
      opacity:0,
    }, {
      scrollTrigger: {
        trigger: div25Ref.current,
        start: "top 110%",
        end: "bottom bottom",
        scrub: true,
      },
      // x: "100%",
      // y: "100%",
      scale:1,
      opacity: 1,
      duration: 1.5,
      ease: "power2.out",
    });
  },[])


  return (
    <div className='mx-auto container  ' >
      <section id="forEmpMobile ">
        <div className='lg:hidden container mx-auto relative my-8'>
          <div className="mt-5 pt-5 empSectionOneleft divPower2Out">
            <h1 className="text-center text-[#bafff7] text-xl">Would you like to Hire a Smart Fellow?</h1>
            <p className="text-white text-center text-sm">We transform entry level talent into smart generalist who can think solve &amp; communicate beyond just their technical skills</p>
          </div>
          <div className="relative mx-5 mt-10 text-center bg-contain bg-no-repeat bg-fixed bg-center empSectionOneRight divPower2Out" id="empSectionOneRight" >
            <div className='aspect-square w-full flex items-center justify-center'>
              <img src={smartFelllows} className="h-[220px]" alt="" />
            </div>
            <img src={smartCircle } className='emp-section-one-img1 absolute top-0 aspect-square m-auto' alt="" />
          </div>
        </div>
        <div className="grid grid-cols-12 pt-5">
          <div className="col-span-12">
            <p className='divPower2Out text-center text-white'>The Smart Fellowship is a Workplace Simulation and Role Play based program where graduates master 9 Soft Skills. These superpowers enhance their ability and add value to your business.</p>
            <h5 ref={div5Ref} className='text-center primary-text pt-3'> And here is how it works!</h5>
          </div>
        </div>
      </section>
      <section id="forEmpMobileSec2">
        <div className="grid grid-cols-12 pt-5">
          <div className="col-span-1"></div>
          <div ref={div6Ref} className="col-span-2 ">
            <img src={one} alt="num1" className='img-fluid w-full h-auto max-lg:h-[48px] listnum-img' />
          </div>
          <div className="col-span-1"></div>
          <div ref={div7Ref} className="col-span-8">
            <h6 className='primary-text'>We scout for ambitious graduates across different streams.</h6>
          </div>
          <div ref={div8Ref} className="col-span-12 text-center">
            <img src={oneEmp} className='img-fluid w-full h-auto' alt="emp one" />
          </div>
        </div>
        <div className="grid grid-cols-12 pt-5">
          <div className="col-span-1"></div>
          <div ref={div9Ref} className="col-span-2 ">
            <img src={two} alt="num1" className='img-fluid w-full h-auto max-lg:h-[48px] listnum-img' />
          </div>
          <div className="col-span-1"></div>
          <div ref={div10Ref} className="col-span-8">
            <h6 className='primary-text'>Pre-assessment for technical skills and testing learnability of soft skills</h6>
          </div>
          <div ref={div11Ref} className="col-span-12 text-center">
            <img src={twoEmp} className='img-fluid w-full h-auto' alt="emp two" />
          </div>
        </div>
        <div className="grid grid-cols-12 pt-5">
          <div className="col-span-1"></div>
          <div ref={div12Ref} className="col-span-2 ">
            <img src={three} alt="num1" className='img-fluid w-full h-auto max-lg:h-[48px] listnum-img' />
          </div>
          <div className="col-span-1"></div>
          <div ref={div13Ref} className="col-span-8">
            <h6 className='primary-text'>Before they join you, Smart Fellows ‘work’ as a core team member of an imaginary company for 6 weeks.</h6>
          </div>
          <div ref={div14Ref} className="col-span-12 text-center">
            <img src={threeEmp} className='img-fluid w-full h-auto' style={{ marginTop: '-30px' }} alt="emp two" />
          </div>
        </div>
        <div className="grid grid-cols-12 pt-5">
          <div className="col-span-1"></div>
          <div ref={div15Ref} className="col-span-2 ">
            <img src={four} alt="num1" className='img-fluid w-full h-auto max-lg:h-[48px] listnum-img' />
          </div>
          <div className="col-span-1"></div>
          <div ref={div16Ref} className="col-span-8">
            <h6 className='primary-text'>
              You get to hire based on data that really matters!{" "}
            </h6>
          </div>
          <div ref={div17Ref} className="col-span-12 text-center mt-3">
            <img src={fourEmp} className='img-fluid w-full h-auto' alt="emp two" />
          </div>
        </div>
      </section>
      <section className='forEmpSec3'>
        <div ref={div18Ref} className="grid grid-cols-12 mt-5">
          <div className="col-span-12 mt-5">
            <p className='text-white text-center'>Smart Fellows won’t ask stupid questions. Instead, they come with superpowers that will Empower your business to thrive!</p>
          </div>
        </div>
        <div ref={div19Ref} className="grid grid-cols-12 my-5">
          <div className="col-span-1"></div>
          <div className="col-span-2">
            <img src={vector} className='img-fluid w-full h-auto' alt="vector" />
          </div>
          <div className="col-span-8">
            <h6 className='text-center text-white'>100+ Mental Models to solve almost any problem</h6>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div ref={div20Ref} className="grid grid-cols-12 my-5">
          <div className="col-span-1"></div>
          <div className="col-span-2">
            <img src={bulb} className='img-fluid w-full h-auto' alt="vector" />
          </div>
          <div className="col-span-8">
            <h6 className='text-center text-white'>200+ Hacks to get work done</h6>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div ref={div21Ref} className="grid grid-cols-12 ">
          <div
            className="col-span-12 aspect-square relative"
            id="empMobileImgOne"
            >
            <img src={innerText} alt="" className='absolute top-0 img-fluid w-full h-auto emp-inner-circ' id="empMobileCircleTextimgInner " style={{ animation: "rotateCounterclockwise 10s linear infinite", }} />
            <img src={outerText} alt="" className='absolute top-0 m-[10%] img-fluid w-[80%] h-auto  max-lg:justify-center max-lg:items-center max-lg:left-0 max-lg:right-[5%] emp-outer-circ max-lg:absolute max-lg:top[-18px] left-[14px] max-lg:max-h-[40rem]' id="empMobileCircleTextimg" style={{ animation: "rotateCounterclockwise 10s linear infinite", }} />
            <div className="w-full flex items-center justify-center aspect-square">
              <img src={smartFelllows} className="h-[220px]" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="forEmpSec4" className='mt-5 pt-5'>
        <div className="grid grid-cols-12 mt-5">
          <div className="col-span-12">
            <h6 ref={div22Ref} className='text-center text-white'>Storylines of the simulation are domain specific. </h6>
            <p ref={div23Ref} className='text-center text-white text-xs font-light'>Smart fellows adapt applications of non-technical skills to their specific domains.</p>
          </div>
        </div>
      </section>
      <section id="forEmpSec5" className="mt-10">
        <div ref={div24Ref}>
          <Slider {...settings}>
            <div className="col-span-12">
            <a href="/our-programs"><img src={financeImgMobile} alt="" className="img-fluid w-full h-auto" /></a>
            </div>
            <div className="col-span-12 ">
              <img src={itImgMobile} alt="" className="img-fluid w-full h-auto" />
            </div>
            <div className="col-span-12 ">
              <img src={mediaImgMobile} alt="" className="img-fluid w-full h-auto" />
            </div>
          </Slider>
        </div>
      </section>
      <div className="mx-4 my-4">
        <TestimonialsCards />
      </div>
      <section className='m-auto' id="mobContactForm">
        <div className="grid grid-cols-12 ">
          <div className="pt-5 mt col-span-12">
            <ContactForm />
          </div>
        </div>
      </section>
    </div>
  )
}

export default MobileForEmp
