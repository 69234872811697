import React from 'react';
import NavigationTabs from '../components/Our-Programs/navigation-tabs';
import Header from '../components/header';

//TODO: Recheck css class const, tabconst for scroll behaviour.
function OurProgram() {
  return (
    <div className='w-full gradient '>
      <Header/>
     
      <NavigationTabs/>
    </div>
  )
}

export default OurProgram
