// About departmen : the most exciting part

export const jobListing = [
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/iv9ww7cxn8vehhqikjjj",
    role: "Curriculum Designer",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "You get to create imaginary companies, their storylines & scenarios through which learners master soft skills & mental models essential for success in the real world of work.",
    responsibilities: [
      "1. Collaborating with the Founder Samyak Chakrabarty (linkedin) to build a curriculum and assessment framework",
      "2. Ideating and collaborating with the writing team to create exciting and effective simulations",
      "3. Validate the effectiveness and real world success of our curriculum",
      "4. Continuously research applications of soft skills in roles across sectors to ensure curriculum and simulation scenarios are in line with industry expectations",
      "5. Partner with the A.I / ML development team to “train” Neuroda - the world's first a.i soft skills coach.",
    ],
    skillRequirements: [
      "● Minimum 2 years of corporate experience in talent development, curriculum design or HR roles.",
      "● Experience in creating curriculum or learning & development programs for corporate talent development",
      "● Strong research skills and ability to synthesize information from a variety of sources.",
      "● General awareness about how artificial intelligence and machine learning works",
      "● Excellent writing and editing skills.",
      "● Ability to work collaboratively in a team environment, taking feedback and iterating on written materials as needed.",
      "● Avid reader of business news and current affairs",
      "● Efficient with digital tools and Google G Suite.",
    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSf4oLQOYaebh8yIH-6Omz7sNELq6kAAfxOTFKOy03Hto-CsXQ/viewform?usp=sf_link",
  },
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/v782q4uyefzfpuhkdxob",
    role: "Business Case Study Writer",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "You will ideate, research, compose & frequently update a bank of real world workplace scenarios for writing simulation storylines for the world’s first immersive soft skills training platform.",
    responsibilities: [
      "1. Creating a content bank of real world workplace scenarios by researching the Internet and other available case study sources to demonstrate use cases of soft skills like critical thinking, creative problem solving and storytelling.",
      "2. Interviewing Founders, CEOs and working professionals from across different enterprises for collecting scenarios and storylines for the scenario bank",
      "3. Collaborating with the Curriculum designer and Creative writer for identifying relevant scenarios from the bank and tweaking them for creating exciting narrative storylines.",
      "4. Verifying the efficacy of a particular scenario in conveying the desired curriculum goals by doing internal and external testing.",
    ],
    skillRequirements: [
      "● At least 2 years of experience in Business or Finance Journalism or academic writing",
      "● Excellent writing and editing skills",
      "● Strong research skills and ability to synthesize information from a variety of sources.",
      "● Ability to work collaboratively in a team environment, taking feedback and iterating on written materials as needed.",
      "● Experience working with narrative content & design is a plus",
      "● Avid reader of business news and current affairs",
      "● Efficient with digital tools and Google G Suite.",
    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSef1oJlN2XvnU-0A0sM0QKNu7FgcByaIc87fl0LAiRn8LSGEw/viewform?usp=sf_link",
  },
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/re7nmjorsvqnck0ys5jp",
    role: "Social Media Copywriter",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "You will be responsible for crafting engaging short format content to convey the importance of superpower soft skills to millions of graduates",
    responsibilities: [
      "● Develop creative and engaging content for our social media platforms that align with our brand voice and messaging.",
      "● Write social media posts, articles, and other written content that informs and engages with our target audience.",
      "● Research industry trends, topics, and competitor strategies to stay up-to-date with the latest social media and content marketing trends.",
      "● Review and edit written content for accuracy, clarity, and grammatical errors.",
      "● Collaborate with the marketing team to ensure that the content aligns with the overall marketing and communication strategy.",
      "● Analyze social media metrics and track the performance of content to improve engagement.",
    ],
    skillRequirements: [
      "● 2 years of relevant experience in social media writing, content creation, or a related field.",
      "● Excellent writing skills and the ability to write in different tones, styles, and formats.",
      "● Highly creative and able to develop engaging content that resonates with our target audience.",
      "● Excellent research skills and the ability to identify trending topics and industry insights.",
      "● Experience in social media analytics, including tracking and reporting on social media metrics.",
      "● Should be highly organized and able to manage multiple projects simultaneously.",
      "● Excellent written and verbal communication skills.",
      "● Efficient with digital tools and Google G Suite.",
    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSdkBGAMUPx07By8w--B4ZI-8EivkHXLS-cH4lzDbE6khRMHlQ/viewform?usp=sf_link",
  },
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/mhyuqbn8jybuvwn0wffr",
    role: "Graphic Designer",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "You will create visually engaging social media graphics, marketing materials, and collateral to enhance our brand and vision. Your designs will captivate our audience, promoting our mission and helping young professionals thrive in the age of AI.",
    responsibilities: [
      "● Creating futuristics and engaging visuals for social media, marketing material and decks in accordance with our brand guidelines.",
      "● Conduct research on various design principles to identify the most suitable approaches for our product and vision.",
      "● Work closely with different teams to develop design concepts and incorporate feedback.",
      "● Assist in the creation of video content by designing motion graphics and visual effects.",
      "● Develop and maintain a consistent visual style and brand identity across all materials.",
    ],
    skillRequirements: [
      "● 2 years of relevant experience as a Graphic designer.",
      "● Portfolio or work samples showcasing design aptitude, creativity, depth of thought and the ability to solve complex design problems.",
      "● Strong understanding of common UX and UI patterns.",
      "● Basic knowledge of layouts, typography, line composition, color, and other graphic design fundamentals.",
      "● Experience with InDesign, Adobe Photoshop, and Illustrator",
      "● Strong creative and analytical skills.",
      "● Compelling portfolio of graphic design work.",
      "● Excellent written and verbal communication skills.",
      "● A passion for learning and a desire to help others succeed.",
      "● Efficient with digital tools and Google G Suite.",
    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSdIxaarg_ZT9x0WL469qOWfU1rs4lBf4l-lQiQjLEdogAKfyg/viewform?usp=sf_link",
  },
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/fxgfvulwoxmqkd9gmam2",
    role: "Narrative Designer & Writer",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "You will ideate & write thought provoking workplace simulation scenarios for the world’s first immersive soft skills training platform",
    responsibilities: [
      "1. Ideating and Writing exciting storylines to engage and teach our learners various soft skills like Creative Problem Solving, Storytelling...etc.",
      "2. Creating various workplace characters with different personalities and powerful dialogues within the storyline to create a more immersive and realistic workplace simulation.",
      "3. Collaborating with the Curriculum Designer ensure storylines are in accordance to set learning goals and objectives.",
      "4. Collaborating with the production department and preparing production sheets for final delivery of the scripts.",
    ],
    skillRequirements: [
      "● At least 2 years of experience in Script writing & Narrative Design",
      "● Excellent writing and editing skills, with the ability to create engaging, immersive, and compelling narratives.",
      "● Strong research skills and ability to synthesize information from a variety of sources.",
      "● Familiarity with game and game development pipelines",
      "● Ability to work collaboratively in a team environment, taking feedback and iterating on written materials as needed.",
      "● Avid reader of business news and current affairs",
      "● Efficient with digital tools and Google G Suite.",
    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSeB9obtLypUo2mDC1DcRwrd7r5ZJLcAlsUqUtTHABFPIh1OGQ/viewform?usp=sf_link",
  },
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/vlqqrfmq92eyqhtv9ct8",
    role: "Comedy Writer (Humor)",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "You will be responsible for crafting witty short format narratives to convince graduates about the importance of soft skills in achieving success at work. Further you will leverage the power of humor to demonstrate how humans can create more value at work than an a.i can. ",
    responsibilities: [
      "● Develop Witty and convincing narratives or storylines for static social media posts, reels and motion graphics  about the importance of superpower soft skills.",
      "● Collaborate with the design team to ensure visuals and written content align in projecting a powerful story",
      "● Research industry trends, topics, and competitor strategies to stay up-to-date with the latest social media and content marketing trends.",
      "● Review and edit written content for accuracy, clarity, and grammatical errors, ensuring a humorous and engaging tone.",
      "● Collaborate with the marketing team to ensure that the content aligns with the overall marketing and communication strategy.",
      "● Analyze social media metrics and track the performance of content to improve content"
    ],
    skillRequirements: [
      "● 2 years of relevant experience in humor/comedy writing, social media content creation, or a related field.",
      "● Excellent writing skills and the ability to write in different tones, styles, and formats, particularly humorous content in English.",
      "● Highly creative and able to develop engaging and funny content that resonates with our target audience.",
      "● Excellent research skills and the ability to identify trending topics and industry insights.",
      "● Highly organized and able to manage multiple creative ideas and execution simultaneously ",
      "● Efficient with digital tools and Google G Suite."
    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSfjEoYEXKP7-EZ9z-F5Qemkcp4u8a9mHVK9Th_BfNkHKNf1-Q/viewform?usp=sf_link",
  },
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/hxmwzuzj8xypeljuymev",
    role: "Learning & Training Manager",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "You will optimize, evolve and validate the world’s first soft skills training curriculum that is being delivered through an immersive & virtual reality platform.",
    responsibilities: [
      "● Collaborating directly with the Founder, Samyak Chakrabarty, to modify and structure our curriculum and assessment framework for the workplace simulation.",
      "● Ideating and collaborating with the writing team to create exciting and effective simulations/storylines",
      "● Validating the effectiveness and real-world success of our curriculum.",
      "● Continuously researching applications of soft skills in roles across sectors to ensure curriculum and simulation scenarios are in line with industry expectations.",
      "● Partnering with the AI/ML development team to “train” Neuroda - the world's first AI soft skills coach."
    ],
    skillRequirements: [
      "● Minimum 2 years of corporate experience in talent development, curriculum design, teaching or HR roles.",
      "● Experience in creating curriculum or managing learning & development programs for corporate talent development",
      "● Strong research skills and ability to synthesize information from a variety of sources.",
      "● General awareness about how artificial intelligence and machine learning works",
      "● Excellent writing and content structuring skills ",
      "● Ability to work collaboratively in a team environment, taking feedback and iterating on written materials as needed.",
      "● Avid reader of business news and current affairs",
      "● Efficient with digital tools and Google G Suite."
    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSfgfU3yg1s1dY1V3NHC86Odl6xVyE_-CHJnlgGJKfamCsoQaQ/viewform?usp=sf_link",
  },
  {
    imgLink:
      "https://res.cloudinary.com/dnluidohg/image/upload/f_auto,q_auto/v1/New%20Workverse%20Website%202023/yjfblfipqri7ylpzqvhl",
    role: "Knowledge Architect",
    department: "Learning Experience",
    compensationRange: "As per industry standard",
    location: "Khar West, Mumbai (this is a work-from-office role)",
    latestJoiningDate: "1st Aug",
    exitingPart:
      "Leveraging your experience in the world of work to construct workplace scenarios for our simulation through which our users can learn how to apply soft skills like critical thinking, problem solving and emotional intelligence",
    responsibilities: [
      "● Develop a continuously updating  content bank of real-world workplace scenarios by leveraging your client servicing/ business development / operational experience to aid the creation of our workplace simulation storylines themed on application of soft skills.",
      "● Interviewing Founders, CEOs and working professionals from across different enterprises for collecting scenarios and storylines from there experiences for the scenario bank themed on application of soft skills across different roles/sectors",
      "● Collaborating with the Curriculum designer and Creative writer for identifying relevant scenarios from the bank and tweaking them for creating immersive workplace simulations "
    ],
    skillRequirements: [
      
      "● At least 2 years of experience in client servicing or business development or operations, with a strong understanding of client interactions and workplace dynamics.",
      "●  Excellent written communication ",
      "●  Strong research skills and the ability to synthesize information from various sources.",
      "● Ability to work collaboratively in a team environment, taking feedback and iterating on written materials as needed.",
      "● Avid reader of business news and current affairs.",
      "●  Proficient with digital tools and Google G Suite."

    ],
    googleFormLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSclgk0_QMUZ0AF6SnCH3rr2_Y7d5zxxt_zPLNpxHrNB6JV2gw/viewform?usp=sf_link",
  }
];
