import React from "react";
const ContactForm = () => {
  //   const [formData, setFormData] = useState({
  //     name: '',
  //     email: '',
  //     message: '',
  //   });

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: value,
  //     }));
  //   };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     // Replace the following alert with your logic to handle form submission
  //     alert('Form submitted successfully!');
  //     // Reset form fields after submission
  //     setFormData({
  //       name: '',
  //       email: '',
  //       message: '',
  //     });
  //   };

  return (
    // <form onSubmit={handleSubmit}>
    <div className="w-full my-10 mx-auto">
      <form className="Contactform relative w-full md:w-[50%] lg:w-[35%] mx-auto">
        <div className="mb-4">
          <div className="HacksText text-[#07edc4]">
            {/*<p className="text-sm text-center"> 200+ Hacks to get work done</p>*/}
            <h1 className="text-center">
              <span className="font-bold">Contact</span> Us
            </h1>
            <p className="text-sm text-center">Fill this form to help us find you the right fellow</p>
          </div>
        </div>
        <div>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            required
            className="name placeholder:#31e2ed66 w-full p-[10px] mb-6  rounded-md  "
            style={{
              background:
                "linear-gradient(93.74deg, rgba(2, 30, 41, 0.58) 12.3%, rgba(2, 30, 41, 0.551528) 68.5%, rgba(2, 30, 41, 0.54) 90.68%)",
              boxShadow: "0 0 14px #07edc44d",
            }}
          />
        </div>
        <div>
          <input
            type="tel"
            id="contact-number"
            name="contact-number"
            placeholder="Contact number"
            required
            className="email placeholder:#31e2ed66 w-full p-[10px] mb-6 text-white"
            style={{
              background:
                "linear-gradient(93.74deg, rgba(2, 30, 41, 0.58) 12.3%, rgba(2, 30, 41, 0.551528) 68.5%, rgba(2, 30, 41, 0.54) 90.68%)",
              boxShadow: "0 0 14px #07edc44d",
              borderRadius: "6px",
            }}
          />
        </div>
        <div>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email address"
            required
            className="email placeholder:#31e2ed66 w-full p-[10px] mb-6 text-white"
            style={{
              background:
                "linear-gradient(93.74deg, rgba(2, 30, 41, 0.58) 12.3%, rgba(2, 30, 41, 0.551528) 68.5%, rgba(2, 30, 41, 0.54) 90.68%)",
              boxShadow: "0 0 14px #07edc44d",
              borderRadius: "6px",
            }}
          />
        </div>
        <textarea
          id="message"
          name="message"
          placeholder="Your message"
          required
          style={{
            background:
              " linear-gradient(93.74deg, rgba(2, 30, 41, 0.58) 12.3%, rgba(2, 30, 41, 0.551528) 68.5%, rgba(2, 30, 41, 0.54) 90.68%)",
            boxShadow: " 0px 0px 14px 0px rgba(7, 237, 196, 0.3)",
          }}
          className="question placeholder:#31e2ed66 w-full min-h-[30vh] p-[10px] mb-6 rounded-md text-white"
        />

        <div className="btnBox mt-[2%] w-full flex justify-center text-right">
          <button
            style={{
              background:
                "linear-gradient(71.42deg, rgba(33, 114, 119, 0.58) -13.32%, rgba(49, 226, 237, 0.54) 114.05%)",
              border: "0.25px solid rgba(255, 255, 255, 1)",
              boxShadow: "0 0 14px #07edc44d",
            }}
            className="submitButton1 p-2 rounded relative"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
