import React from "react";
import road from "../../assets/Our Programs/Schedule/road.png";
import mobRoad from "../../assets/Our Programs/Schedule/mob-schedule.png";
import Arrow from "../../assets/Our Programs/arrow.png";

function Schedule({ handleNext }: { handleNext: (currTab: string) => void }) {
  return (
    <div className="container mx-auto pt-6 md:pt-12">
        <div id="page-header" className='flex flex-col md:flex-row justify-between   py-5  top-20 '>
        <div className='w-full md:w-1/2'>

          <div className='text-[#31e2ed] mb-5 text-left'>
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className='col-span-12 md:col-span-3 lg:col-span-6 text-right'>
          <button style={{background:"linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)"}} className='shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white '>
            <div className='whitespace-nowrap' style={{border:'3px solid black',borderRadius:'4px',padding: '11px',paddingLeft: '25px',paddingRight: '25px',margin:'3px',marginRight:'-2px',marginLeft:'-1px',textShadow:" 0 0 10px #fff"}}>Apply Now {'>>'}</div>
          </button>
        </div>
      </div>
    
      
     <div className="flex flex-col">
     <h2 className="text-[#dbfdff] mb-4">Schedule</h2>
      <div className="grid grid-cols-12 order-2 md:order-1" style={{ padding: "5%" }}>
     
       
        <div className="col-span-12 ">
          <img src={road} className="max-w-full h-auto hidden md:block" alt="" />
          <img src={mobRoad} className="max-w-full h-auto block md:hidden" alt="" />
        </div>

     
      </div>
   
     <div className="order-1 md:order-2">
      <div className="font-bold text-left text-[28px] leading-[34px] text-[#dbfdff] mb-8">
        <h1>Total commitment- 48 hours</h1>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-6">
          <div>
            <p className="text-justify text-base lg:text-[22px] leading-[22px] text-[#DBFDFF] mb-4">
              Commit just 8-9 hours out of your week and see how fast your
              workplace intelligence gets activated! Here’s a sample schedule of
              what one week in the program looks like
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-6">
          <table className="border-collapse w-full mt-[5%]">
            <thead>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Program orientation
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  2 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                   In-person (Mumbai)
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Masterclasses
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  4 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Online
                </td>
              </tr>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Weekly Meetings
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  5 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px]  leading-[26px] text-[#BAFFF7]">
                  Online
                </td>
              </tr>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Self-paced-tasks
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  22 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Online
                </td>
              </tr>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Self-paced challenges
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  3 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px]  leading-[26px] text-[#BAFFF7]">
                  Online
                </td>
              </tr>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Self-paced study
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px]  leading-[26px] text-[#BAFFF7]">
                  8 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Online
                </td>
              </tr>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Professional development sessions
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  2 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Online
                </td>
              </tr>
              <tr>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  Graduation ceremony
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  2 hours
                </td>
                <td className="p-2 text-center border border-[#BAFFF7] text-base lg:text-[22px] leading-[26px] text-[#BAFFF7]">
                  In-person (Mumbai)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
      </div>

      <div className="pb-12">
        <hr className="hr1 bg-[#BAFFF7] border-t-2 border-white mt-5" />
        <div className="grid grid-cols-12">
          <div className="md:col-span-9"></div>
          <div className="col-span-12 md:col-span-3 justify-end">
            <button
              className="flex gap-12 justify-center mt-4  py-4 w-full border-[0.5px] border-white bg-transparent rounded cursor-pointer ease-linear duration-300"
              onClick={(e) => handleNext("tab-8")}
              style={{ width: "100%", alignContent: "centre" }}
            >
              <div className="txt font-Magistral text-lg font-light text-center text-[#DBFDFF]">
                Next: FAQs
              </div>
              <img
                src={Arrow}
                alt=""
                width={20}
                height={30}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
