import React from "react";
import ID from "../../assets/Our Programs/HowYouLearn/ID.svg";
import Maze from "../../assets/Our Programs/HowYouLearn/Maze.svg";
import Mobile from "../../assets/Our Programs/HowYouLearn/Mobile.svg";
import Tools from "../../assets/Our Programs/HowYouLearn/tools.svg";
import MasterClass from "../../assets/Our Programs/HowYouLearn/MasterClass.svg";
import play from "../../assets/Our Programs/HowYouLearn/play.svg";
import Arrow from "../../assets/Our Programs/arrow.png";

export default function HYL({
  handleNext,
}: {
  handleNext: (currTab: string) => void;
}) {
  return (
    <div className="container mx-auto pt-6 md:pt-12">
      <div
        id="page-header"
        className="flex flex-col md:flex-row justify-between   py-5  top-20 "
      >
        <div className="w-full md:w-1/2">
          <div className="text-[#31e2ed] mb-5 text-left">
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className="col-span-12 md:col-span-3 lg:col-span-6 text-right">
          <button
            style={{
              background:
                "linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)",
            }}
            className="shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white "
          >
            <div
              className="whitespace-nowrap"
              style={{
                border: "3px solid black",
                borderRadius: "4px",
                padding: "11px",
                paddingLeft: "25px",
                paddingRight: "25px",
                margin: "3px",
                marginRight: "-2px",
                marginLeft: "-1px",
                textShadow: " 0 0 10px #fff",
              }}
            >
              Apply Now {">>"}
            </div>
          </button>
        </div>
      </div>
      <div className=" mt-5 py-5">
        <div className="text-[#dbfdff] font-Magistral  font-bold leading-[43px] text-left tracking-normal">
          <h1 className="text-[#dbfdff] font-bold mb-8">Learn by doing</h1>
        </div>

        <div className="grid">
          <div>
            <p className="font-Magistral   text-[#dbfdff] text-justify mb-8">
              The Smart Fellowship is a role play and workplace simulation based
              learning journey. Instead of passively listening to gyaan, you
              master soft skills, mental models and workplace hacks by doing! 
            </p>
            <p className="font-Magistral   text-[#dbfdff] text-justify mt-[6vh] mb-8">
              No two situations that you face are ever the same, which is where
              simulated experiences give you a huge advantage. Scientifically
              speaking, they help create new ‘neural pathways’ or connections in
              your brain that ensure you’re prepared to tackle similar scenarios
              in real life instinctively by adapting to the situation and
              contextualizing your decisions accordingly.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row  gap-8">
        <div className="w-full md:w-4/5" >
        
            <h2 className="font-Magistral font-bold text-[#31e2ed] mb-8 ">
              01. Work in an Imaginary Company
            </h2>
          
          <p className="font-Magistral  text-[#dbfdff]  text-justify mb-8 ">
            You take on the role of an ‘Assistant CFO’ in an imaginary company
            whose business is to provide Financial Services & Consultancy to
            struggling startups. Your mission is to save your client’s business
            from shutting down alongside your colleagues (batchmates)! You will
            have to engage with tough bosses, manipulative characters and hard
            to crack associates along the way.
          </p>
        </div>
    
          <div className=" w-full h-full self-center flex justify-center">
            <img src={ID} alt="ID" style={{ marginRight: "20px" }} />
          </div>
        
      </div>

      <div className="flex flex-col md:flex-row  gap-8 mt-16">
        <div  className="w-full md:w-3/5">
        
            <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-8 ">
              02. Gripping Storyline
            </h2>
       

          <p className="font-Magistral  text-justify text-[#dbfdff] mt-8  mb-8 ">
            Not a moment will be without action, tension and tough decisions to
            make. Apply mental models, hacks and soft skills to solve twisted
            problems, draft compelling communication, negotiate deals and find
            the smartest ways out of multi-layered situations.
          </p>
          <p className="font-Magistral  text-justify text-[#dbfdff] mt-8  mb-8 ">
            All of these are superpowers for success in the real world.
          </p>
        </div>
   
          <div className="flex justify-center self-center">
            <img
              src={Maze}
              className="img-fluid"
              alt="Maze"
              style={{ marginRight: "20px" }}
            />
        </div>
      </div>

      <div className="flex flex-col md:flex-row  gap-8 mt-16">
      
        <div className="w-full md:w-4/5">
          
            <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4 ">
              03. Get Work Done
            </h2>
         

          <p className="font-Magistral text-justify text-[#dbfdff] mt-8 ">
            Solving tasks, challenges and group projects in this storyline will
            train your brain to build new neural pathways so that nothing takes
            you by surprise in your actual job.
          </p>
        </div>
       
          <div className="self-center flex justify-center w-full h-full  ">
            <img
              src={Mobile}
              className="img-fluid"
              alt="Mobile"
              style={{ marginRight: "20px" }}
            />
          </div>
        
      </div>

      <div className="flex flex-col md:flex-row  gap-8 mt-16">
      
        <div className="w-full md:w-4/5">
          
            <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4 ">
              04. Learn New Tools
            </h2>
        
         
            <p className=" font-Magistral  text-justify text-[#dbfdff] mt-[7vh] ">
              You will practice using tools such as Slack, Miro & Chat GPT to
              complete your tasks efficiently.
            </p>
            <p className=" font-Magistral  text-justify text-[#dbfdff] mt-[7vh] ">
              Proficiency in such platforms is a must in the new world of work.
            </p>
         
        </div>
       
          <div className="self-center flex justify-center  w-full h-full">
            <img
              src={Tools}
              className="img-fluid"
              alt="Mobile"
              style={{ marginRight: "20px" }}
            />
          </div>
       
      </div>

      <div className="flex flex-col md:flex-row gap-8 mt-16">
        <div className="w-full md:w-4/5">
       
            <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4 ">
              05. Masterclasses by Real World Masters
            </h2>
       

          <p className=" font-Magistraltext-justfiy text-[#dbfdff] mt-[7vh] ">
            While you’re ‘working’, spend 1 hour every week learning from real
            world experts who will activate your superpower soft skills by
            showing you how they have enhanced their own superpowers to achieve
            success!
          </p>
        </div>

        
          <div className="flex justify-center self-center w-full h-full">
            <img
              src={MasterClass}
              className="img-fluid"
              alt="Mobile"
              style={{ marginRight: "20px" }}
            />
          </div>
      
      </div>

      <div className="flex flex-col md:flex-row mt-16 gap-8">
        <div className="w-full md:w-4/5">
       
            <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4 ">
              06. 50+ Hours of Secondary Content
            </h2>
      

          <p className=" font-Magistral text-justify text-[#dbfdff] mt-[7vh] ">
            We curate articles, podcasts and videos on topics that help you
            achieve your goals in the simulation while also enhancing your
            general exposure that is essential to have an edge amongst others
            who are competing for the next promotion.
           </p>
        </div>
        
          <div className="flex justify-center self-center w-full h-full">
            <img
              src={play}
              className="img-fluid"
              alt="Mobile"
              style={{ marginRight: "20px" }}
            />
          </div>
       
      </div>

      <div className="">
        <div className="w-full lg:w-4/5 text-[#31E2ED] font-Magistral text-[28px] font-bold leading-[46px] tracking-normal text-left mt-[15vh]">
          <h2>
            {" "}
            You may wonder, why don’t I just do an internship instead? <br />
            Well here’s why this experience will be more valuable…
          </h2>
        </div>
        <div className="www flex-[2.5] mr-[10vw]" style={{ marginTop: "20px" }}>
          <p className=" font-Magistral   text-left text-[#dbfdff] mt-[7vh] ">
            <ul className="space-y-4 list-disc font-Magistral text-base mx-4 text-justify  lg:text-[22px] font-base leading-[26px]  text-[#dbfdff] mt-[9vh] flex-2">
              <li>
                <p>
                  Your internship responsibilities are generally themed on the
                  technical or theoretical aspect of your field rather than
                  thinking-based tasks.
                </p>
              </li>
              <li>
                <p>
                  You are not in the driver's seat there whereas in the Smart
                  Fellowship - your decisions, actions, and communications have
                  consequences which act like positive pressure to activate new
                  areas in your brain.
                </p>
              </li>
              <li>
                <p>
                  How / what you learn during internships is heavily dependent
                  on the involvement of your boss in teaching you and also on
                  the nature of tasks that you get.
                </p>
              </li>
              <li>
                <p>
                  In the Smart fellowship, you get to do meaningful and
                  significant tasks which offer a better platform for mastering
                  soft skills.
                </p>
              </li>
            </ul>
            <h1 className="para1 font-Magistral text-[22px] font-base leading-[26px] text-left text-[#dbfdff] mt-[7vh] flex-2">
              All set to become a Smart Fellow?
            </h1>
          </p>
        </div>
      </div>

      <div className="pb-12">
        <hr className="hr1 bg-[#BAFFF7] border-t-2 border-white mt-5" />
        <div className="grid grid-cols-12">
          <div className="md:col-span-9"></div>
          <div className="col-span-12 md:col-span-3 justify-end">
            <button
              className="flex gap-12 justify-center mt-4  py-4 w-full border-[0.5px] border-white bg-transparent rounded cursor-pointer ease-linear duration-300"
              onClick={(e) => handleNext("tab-4")}
              style={{ width: "100%", alignContent: "centre" }}
            >
              <div className="txt font-Magistral text-lg font-light text-center text-[#DBFDFF]">
                Next: Placement Support
              </div>
              <img
                src={Arrow}
                alt=""
                width={20}
                height={30}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
