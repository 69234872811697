import React, { useEffect, useRef, useState } from "react";
import step1 from "../assets/career/steps-1.svg";
import step2 from "../assets/career/steps-2.svg";
import step3 from "../assets/career/steps-3.svg";
import step4 from "../assets/career/steps-4.svg";
import currentCareerImage from "../assets/career/current-career-image.svg";
import Header from "../components/header";
import  gsap  from "gsap";
import { ScrollTrigger } from "gsap/all";
import { jobListing } from "../data/jobs";
import JobApply from "../components/Careers/JobApply";
import Footer from "../components/Footer";
function Careers() {
  // States to manage job application popup form
  const [open, setOpen] = useState(false);
  const [selectedJd, setSelectedJd] = useState({});

  gsap.registerPlugin(ScrollTrigger);
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);

  const div3Ref = useRef(null);
  const div4Ref = useRef(null);

  const div5Ref = useRef(null);
  const div6Ref = useRef(null);

  const div7Ref = useRef(null);
  const div8Ref = useRef(null);
  const div9Ref = useRef(null);

  useEffect(() => {
    const div1 = div1Ref.current;
    const div2 = div2Ref.current;

    const div3 = div3Ref.current;
    const div4 = div4Ref.current;

    const div5 = div5Ref.current;
    const div6 = div6Ref.current;

    const div7 = div7Ref.current;
    const div8 = div7Ref.current;
    const div9 = div7Ref.current;

    gsap.fromTo(
      div1,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );

    gsap.fromTo(
      div2,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div2,
          start: "top bottom",
          end: "bottom 40%",
          scrub: true,
        },
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );

    gsap.fromTo(
      div3,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div3,
          start: "top bottom",
          end: "bottom 50%",
          scrub: true,
        },
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );

    gsap.fromTo(
      div4,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div4,
          start: "top bottom",
          end: "bottom 50%",
          scrub: true,
        },
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );

    gsap.fromTo(
      div5,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div5,
          start: "top bottom",
          end: "bottom 50%",
          scrub: true,
        },
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );

    gsap.fromTo(
      div6,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div6,
          start: "top bottom",
          end: "bottom 50%",
          scrub: true,
        },
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      }
    );

    // gsap.fromTo(
    //   div7,
    //   {
    //     scale: 0,
    //     opacity: 0,
    //   },
    //   {
    //     scrollTrigger: {
    //       trigger: div7,
    //       start: "top bottom",
    //       end: "bottom 70%",
    //       scrub: true,
    //     },
    //     scale: 1,
    //     opacity: 1,
    //     duration: 1,
    //     ease: "power2.inOut",
    //   }
    // );
  }, []);

  return (
    <div className="w-full gradient overflow-x-hidden">
      <JobApply setOpen={setOpen} open={open} selectedJd={selectedJd}/>
      <Header />
      <div className="w-full mx-auto container mt-4">
        <section id="careerIntroSection">
          {/* <div className="bg-shadow"></div>
      <div className="bg-shadow-left"></div>
      <div className="bg-shadow-right"></div> */}
          <div ref={div1Ref} className="grid grid-cols-1 mt-5 pt-5">
            <div className="md:col-span-3 ">
              <h1 className="text-[#bafff7] text-center font-bold my-2">
                Working at Workverse is a state of mind.
              </h1>
              <h3 className="text-white text-center font-light">
                We are a room full of creators, thinkers, solvers and curious
                individuals for whom their role here is not a “job”, but a way
                to play their part in building something meaningful for the
                world..
              </h3>
              <h3 className="text-white text-center font-light my-5">
                If this aligns with you, apply to join us!
              </h3>
            </div>
          </div>
        </section>
        <section id="hiringProcessSection">
        
           
              <h3 ref={div2Ref} className="text-[#31e2ed] text-center ">
                Our hiring processs is as follows
              </h3>
              <div className="flex flex-col">
              <div className="order-2">
              <h3 className="text-white font-light text-center mb-5">
                We follow a strict no work from home policy.
              </h3>
              <h3 className="text-white font-light text-center">
                Afterall, could the Beatles have created their everlasting magic
                if they collaborated on zoom?
              </h3>
            </div>
        
         
          <div className="grid  justify-center order-1 mb-8">
            <div className="md:col-span-5">
              <ul className="list-group">
                <li
                  ref={div3Ref}
                  className="list-group-item my-2  border-0 bg-transparent w-full flex-col md:flex-row flex  lg:justify-start justify-center items-center"
                >
                  <div className="step-icon text-center md:text-left">
                    <img
                      src={step1}
                      alt=""
                      className="w-full h-auto  py-4 md:p-1"
                    />
                  </div>
                  <span className="px-5">
                    <h3 className="font-bold text-[#31e2ed] text-center md:text-left">
                      STEP 1
                    </h3>
                    <h3 className="text-white sm:font-light text-center md:text-left ">
                      Submission of CV
                    </h3>
                  </span>
                </li>
                <li
                  ref={div4Ref}
                  className="list-group-item my-2  border-0 bg-transparent flex flex-col md:flex-row  lg:justify-start justify-center items-center"
                >
                  <div className="step-icon text-center md:text-left">
                    <img
                      src={step2}
                      alt=""
                      className="w-full h-auto py-4 md:p-1"
                    />
                  </div>
                  <span className="px-5">
                    <h3 className="font-bold text-[#31e2ed] text-center md:text-left">
                      STEP 2
                    </h3>
                    <h3 className="text-white sm:font-light text-center md:text-left">
                      First Interview with Team Leader
                    </h3>
                  </span>
                </li>
                {/* <div className="bg-shadow"></div>
      <div className="bg-shadow-left"></div>
      <div className="bg-shadow-right"></div> */}
                <li
                  ref={div5Ref}
                  className="list-group-item my-2  border-0 bg-transparent flex flex-col md:flex-row lg:justify-start  justify-center items-center"
                >
                  <div className="step-icon text-center md:text-left">
                    <img
                      src={step3}
                      alt=""
                      className="w-full h-auto py-4 md:p-1"
                    />
                  </div>
                  <span className="px-5">
                    <h3 className="font-bold text-[#31e2ed] text-center md:text-left">
                      STEP 3
                    </h3>
                    <h3 className="text-white sm:font-light text-center md:text-left">
                      Short Assignment
                    </h3>
                  </span>
                </li>
                <li
                  ref={div6Ref}
                  className="list-group-item my-2   border-0 bg-transparent flex flex-col md:flex-row  justify-center lg:justify-start items-center"
                >
                  <div className="step-icon  text-center md:text-left">
                    <img
                      src={step4}
                      alt=""
                      className="img-fluid w-full h-auto py-4 md:p-1"
                    />
                  </div>
                  <span className="px-5">
                    <h3 className="font-bold text-[#31e2ed]  text-center md:text-left">
                      STEP 4
                    </h3>
                    <h3 className="text-white sm:font-light text-center md:text-left ">
                      Final Interview with Founder
                    </h3>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          </div>
        </section>
        <section ref={div7Ref} id="vacancySection" className="mb-4">
          {/* <div className="bg-shadow"></div>
      <div className="bg-shadow-left"></div>
      <div className="bg-shadow-right"></div> */}
          <div className="grid my-5">
            <div className="md:col-span-12 mb-6">
              <h3 className="text-white font-bold">Current Vacancy</h3>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 justify-center gap-6">
            {/* Loop through all the available code below */}
            {jobListing.map((job, index) => {
              {/* Job Box */}
            return <div style={{
                borderRadius: '11px',
                zIndex: '2 !important',
                background: "linear-gradient(93.74deg, #00c1ae7a 12.3%, #04554e99 90.68%), linear-gradient(254.42deg, #fff0f07a 0%, #fff0f03d 100.33%)",
              }}>
              <div className="card card-col-careers">
                <img src={job.imgLink} className="card-img-top w-full" alt="" />
                <div className="mx-3 flex items-center justify-between my-4">
                  <h5 className="card-title text-white">
                    {job.role}
                  </h5>
                  <button
                    style={{
                      background:
                        " linear-gradient(93.93deg, rgba(49, 226, 237, 0.61) -0.43%, #00C1AE 102.39%)",
                      boxShadow:
                        "0px 4px 9px rgba(186, 255, 247, 0.09), 0px 0px 4px rgba(7, 237, 196, 0.3)",
                      borderRadius: "6px",
                    }}
                    className="btn career-applynow-btn relative w-32 py-2"
                    onClick={() => {
                      setSelectedJd(job);
                      setOpen(true);
                    }}
                  >
                    APPLY NOW
                  </button>
                </div>
              </div>
            </div>
          })}

            {/* <div style={{
              borderRadius: '11px',
              zIndex: '2 !important',
              background: "linear-gradient(93.74deg, #00c1ae7a 12.3%, #04554e99 90.68%), linear-gradient(254.42deg, #fff0f07a 0%, #fff0f03d 100.33%)",
            }}>
              <div className="card card-col-careers">
                <img src={currentCareerImage} className="card-img-top w-full" alt="" />
                <div className="mx-3 flex items-center justify-between my-4">
                  <h5 className="card-title text-white">
                    Chief Technology Officer
                  </h5>
                  <button
                    style={{
                      background:
                        " linear-gradient(93.93deg, rgba(49, 226, 237, 0.61) -0.43%, #00C1AE 102.39%)",
                      boxShadow:
                        "0px 4px 9px rgba(186, 255, 247, 0.09), 0px 0px 4px rgba(7, 237, 196, 0.3)",
                      borderRadius: "6px",
                    }}
                    className="btn career-applynow-btn relative w-32 py-2"
                  >
                    APPLY NOW
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <section className='h-24'>
        </section>
      </div>
    
    </div>
  );
}

export default Careers;
