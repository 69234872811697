import React, { useLayoutEffect, useRef, useState } from "react";
import Advantage from "./Advantage";
import Schedule from "./Schedule";
import HYL from "./HYL";
import FAQs from "./Faqs";
import WhoIsThisFor from "./WhoIsThisFor";
import Intro from "./Intro";
import Placement from "./Placement";
import WhatYouLearn from "./WhatYouLearn";

const NavigationTabs = () => {
 
  const [activeTab, setActiveTab] = useState("tab-1");
  const [tabContentHeight, setTabContentHeight] = useState(0);
  const tabHandler = (currTab: string) => {
    setActiveTab(currTab);
 
    document.getElementById("tabs-content")?.scrollTo(0, 0);
  };

  useLayoutEffect(() => {
    window.onresize = () => {
      const wH = window.innerHeight;
      const tH = document.getElementById("tabs-header")?.scrollHeight || 0;
      const pH = document.getElementById("page-header")?.scrollHeight || 0;
      const mH = document.getElementById("main-header")?.scrollHeight || 0;
      setTabContentHeight(wH - tH  - mH - 5);
    };
    const wH = window.innerHeight;
    const tH = document.getElementById("tabs-header")?.scrollHeight || 0;
    const pH = document.getElementById("page-header")?.scrollHeight || 0;
    const mH = document.getElementById("main-header")?.scrollHeight || 0;
    setTabContentHeight(wH - tH - mH - 5);
  }, []);

  return (
    <>
      <div
        id="tabs-header"
    
        className="w-full flex justify-between border-t border-b border-white overflow-auto scrollbar-hide"
      >
        <div
          onClick={() => tabHandler("tab-1")}
          className={`w-48   cursor-pointer opacity-70  h-auto text-center px-4 py-2 text-white  ${
            activeTab === "tab-1" && "nav-link"
          }`}
        >
          Intro
        </div>
        <div
          onClick={() => tabHandler("tab-2")}
          className={`w-48  cursor-pointer opacity-70  whitespace-nowrap h-auto text-center px-4 py-2 text-white ${
            activeTab === "tab-2" && "nav-link"
          }`}
        >
          What you learn
        </div>
        <div
          onClick={() => tabHandler("tab-3")}
          className={`w-48 cursor-pointer opacity-70  h-auto   whitespace-nowrap text-center px-4 py-2 text-white  ${
            activeTab === "tab-3" && "nav-link"
          }`}
        >
          How you learn
        </div>
        <div
          onClick={() => tabHandler("tab-4")}
          className={`w-48 cursor-pointer opacity-70  h-auto   whitespace-nowrap text-center px-4 py-2 text-white  ${
            activeTab === "tab-4" && "nav-link"
          }`}
        >
          Placement support
        </div>
        <div
          onClick={() => tabHandler("tab-5")}
          className={`w-48  cursor-pointer opacity-70 h-auto   whitespace-nowrap text-center px-4 py-2 text-white  ${
            activeTab === "tab-5" && "nav-link"
          }`}
        >
          Advantages
        </div>
        <div
          onClick={() => tabHandler("tab-6")}
          className={`w-48  cursor-pointer opacity-70 h-auto   whitespace-nowrap text-center px-4 py-2 text-white  ${
            activeTab === "tab-6" && "nav-link"
          }`}
        >
          Who is this for
        </div>
        <div
          onClick={() => tabHandler("tab-7")}
          className={`w-48  cursor-pointer opacity-70 h-auto   whitespace-nowrap text-center px-4 py-2 text-white ${
            activeTab === "tab-7" && "nav-link"
          }`}
        >
          Schedule
        </div>
        <div
          onClick={() => tabHandler("tab-8")}
          className={`w-48  cursor-pointer opacity-70 h-auto   whitespace-nowrap text-center px-4 py-2 text-white  ${
            activeTab === "tab-8" && "nav-link"
          }`}
        >
          FAQs
        </div>
      </div>
      <div
        id="tabs-content"
        className="overflow-auto scrollbar-hide"
        style={{
          height: tabContentHeight,
        }}
      >
        {activeTab === "tab-1" && <Intro handleNext={tabHandler} />}
        {activeTab === "tab-2" && <WhatYouLearn handleNext={tabHandler} />}
        {activeTab === "tab-3" && <HYL handleNext={tabHandler} />}
        {activeTab === "tab-4" && <Placement handleNext={tabHandler} />}
        {activeTab === "tab-5" && <Advantage handleNext={tabHandler} />}
        {activeTab === "tab-6" && <WhoIsThisFor handleNext={tabHandler} />}
        {activeTab === "tab-7" && <Schedule handleNext={tabHandler} />}
        {activeTab === "tab-8" && <FAQs />}
      </div>
    </>
  );
};

export default NavigationTabs;
