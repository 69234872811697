import React from "react";
import Img1 from "../../assets/Our Programs/WhoIsThisFor/Img1.svg";
import Img2 from "../../assets/Our Programs/WhoIsThisFor/Img2.svg";
import Img3 from "../../assets/Our Programs/WhoIsThisFor/Img3.svg";
import Arrow from "../../assets/Our Programs/arrow.png";

function WhoIsThisFor({
  handleNext,
}: {
  handleNext: (currTab: string) => void;
}) {
  return (
    <div className="container mx-auto pt-6  md:pt-12">
      <div
        id="page-header"
        className="flex flex-col md:flex-row justify-between   py-5  top-20 "
      >
        <div className="w-full md:w-1/2">
          <div className="text-[#31e2ed] mb-5 text-left">
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className="col-span-12 md:col-span-3 lg:col-span-6 text-right">
          <button
            style={{
              background:
                "linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)",
            }}
            className="shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white "
          >
            <div
              className="whitespace-nowrap"
              style={{
                border: "3px solid black",
                borderRadius: "4px",
                padding: "11px",
                paddingLeft: "25px",
                paddingRight: "25px",
                margin: "3px",
                marginRight: "-2px",
                marginLeft: "-1px",
                textShadow: " 0 0 10px #fff",
              }}
            >
              Apply Now {">>"}
            </div>
          </button>
        </div>
      </div>

      <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
        <b>
          <span>01.</span> Mindset
        </b>
      </h2>

      <div className="grid grid-cols-12 mt-4 px-5 mb-8">
        <div className="col-span-12 md:col-span-8">
          <p className="text-[#dbfdff]">
            The Smart Fellowship is for the ambitious ones who want to fast
            track their journey to growth and leadership. Think of it as net
            practice where, if you succeed, you automatically qualify to play
            the big game.
          </p>
        </div>
      </div>

      <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
        <b>
          <span>02.</span> Stage of Career
        </b>
      </h2>

      <div className="grid grid-cols-12 px-5 mt-5 mx-3 mb-8">
        <div className="md:col-span-3"></div>
        <div className="col-span-12 md:col-span-3 ">
          <div>
            <img src={Img1} className="imgWhoIsThisFor" alt="" />
          </div>
          <div className="text-[#dbfdff] text-center mt-3">
            <p>
              Final year college student in finance, commerce or a
              banking-related field
            </p>
          </div>
        </div>
       

        <div className="col-span-12 md:col-span-1 d-flex justify-center self-center">
          <h5 className="text-white text-center my-4 ">OR</h5>
        </div>
        <div className="col-span-12 md:col-span-3">
          <div>
            <img src={Img3} className="imgWhoIsThisFor" alt="" />
          </div>
          <div className="text-[#dbfdff] text-center mt-3">
            <p>
              Currently employed with 1 month to 2 years of work experience, and
              want to switch to a better job
            </p>
          </div>
        </div>
        <div className="md:col-span-3"></div>
      </div>
      <p className="w-full md:w-3/5 mx-auto text-center mb-4">
        In addition to fulfilling either of the above criteria, you can also be
        pursuing competitive exams like CA / CS, and want to enhance your
        non-technical skills
      </p>
      <h2 className="font-Magistral font-bold text-[#31e2ed] mb-4">
        <b>
          <span>03.</span> Job Profiles
        </b>
      </h2>

      <div className=" mt-4 px-5">
        <div className="px-4">
          <ul className="text-[#dbfdff] space-y-2 list-disc">
            <li>
              <p>Finance, accounts and auditing</p>
            </li>
            <li>
              <p>Vendor and purchase management</p>
            </li>
            <li>
              <p>Banking and insurance services </p>
            </li>
            <li>
              <p>Statutory compliance</p>
            </li>
            <li>
              <p>Investment banking</p>
            </li>
            <li>
              <p>Risk management</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="pb-12">
        <hr className="hr1 bg-[#BAFFF7] border-t-2 border-white mt-5" />
        <div className="grid grid-cols-12">
          <div className="md:col-span-9"></div>
          <div className="col-span-12 md:col-span-3 justify-end">
            <button
              className="flex gap-12 justify-center mt-4  py-4 w-full border-[0.5px] border-white bg-transparent rounded cursor-pointer ease-linear duration-300"
              onClick={(e) => handleNext("tab-7")}
              style={{ width: "100%", alignContent: "centre" }}
            >
              <div className="txt font-Magistral text-lg font-light text-center text-[#DBFDFF]">
                Next: Schedule
              </div>
              <img
                src={Arrow}
                alt=""
                width={20}
                height={30}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoIsThisFor;
