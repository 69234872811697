import React from "react";
import Google from "../../assets/Our Programs/Advantages/google.svg";
import Growth from "../../assets/Our Programs/Advantages/growth.svg";
import Placement from "../../assets/Our Programs/Advantages/placement.svg";
import Supporton from "../../assets/Our Programs/Advantages/support.svg";
import Arrow from "../../assets/Our Programs/arrow.png";

const Advantage = ({
  handleNext,
}: {
  handleNext: (currTab: string) => void;
}) => {
  return (
    <div className="container mx-auto pt-6 md:pt-12">
      <div id="page-header" className='flex flex-col md:flex-row justify-between   py-5  top-20 '>
        <div className='w-full md:w-1/2'>

          <div className='text-[#31e2ed] mb-5 text-left'>
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className='col-span-12 md:col-span-3 lg:col-span-6 text-right'>
          <button style={{background:"linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)"}} className='shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white '>
            <div className='whitespace-nowrap' style={{border:'3px solid black',borderRadius:'4px',padding: '11px',paddingLeft: '25px',paddingRight: '25px',margin:'3px',marginRight:'-2px',marginLeft:'-1px',textShadow:" 0 0 10px #fff"}}>Apply Now {'>>'}</div>
          </button>
        </div>
      </div>
      <h2 className=" w-full lg:w-1/2 text-[#dbfdff] mb-4">
        <b>Smart Fellows are in-demand in the new world of work</b>
      </h2>

      <div>
        
        <div className="mt-5 flex items-center justify-center">
          <img className="max-w-full h-auto" src={Google} alt="" />
        </div>
        <p className="mt-5 text-[#DBFDFF]">
            Here’s what you get at the end of this program.
        </p>
      </div>

      <div>
        <h2 className="font-Magistral  font-bold text-[#31e2ed]  mt-8 mb-4">
          <b>
            01. <span>Growth</span>
          </b>
        </h2>
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 md:col-span-8 mt-5">
            <ul className="list-disc text-base space-y-4 lg:text-[1.25rem] text-[#dbfdff] text-justify container">
              <li>
                <p>
                  This experience empowers you to immediately start thinking and
                  taking actions independently instead of having to rely on
                  instructions or support
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  The mental models learned will enable you to do work that
                  others cannot, ensuring that you get noticed by your seniors
                  for quicker promotions
                </p>
              </li>
            </ul>
          </div>
          <div className="col-span-12 md:col-span-4">
            <img className="max-w-full h-auto" src={Growth} alt="" />
          </div>
        </div>
      </div>

      <div className="mt-[5vh]">
        <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
          <b>
            02. <span>Placement</span>
          </b>
        </h2>
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 md:col-span-8 mt-5">
            <ul className="list-disc space-y-4 text-base lg:text-[1.25rem] text-[#dbfdff] text-justify container">
              <li>
                <p>Guaranteed job offer</p>
              </li>
              <li>
                <p> 10-15% higher negotiating power</p>
              </li>
              <li>
                <p>
                  {" "}
                  Unique edge in a market where 250+ people apply for the same
                  job with similar degrees and certifications
                </p>
              </li>
            </ul>
          </div>
          <div className="col-span-12 md:col-span-4">
            <img className="max-w-full h-auto" src={Placement} alt="" />
          </div>
        </div>
      </div>

      <div className="mt-[5vh]">
        <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
          <b>
            03. <span>Support on the Job</span>
          </b>
        </h2>
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 md:col-span-8 mt-5">
            <ul className="list-disc space-y-4 text-base lg:text-[1.25rem] text-[#dbfdff] text-justify container">
              <li>
                <p>
                  Access to 100+ mental models that will help you tackle all
                  challenges or tasks in the real world of work
                </p>
              </li>
              <li>
                <p> 50+ hours of proprietary training content</p>
              </li>
              <li>
                <p>
                  {" "}
                  Lifetime access to masterclass recordings, even ones that
                  happen after your batch is finished
                </p>
              </li>
            </ul>
          </div>
          <div className="col-span-12 md:col-span-4">
            <img className="max-w-full h-auto" src={Supporton} alt="" />
          </div>
        </div>
      </div>

      {/* <div className="block md:hidden">
      <div className="grid grid-cols-12 justify-between ">
      <div><h4 className="text-[#31E2ED] mt-5" >
              <b>03. <span>Support on the Job</span></b>
            </h4></div>
            <div className="md:col-span-4">
            <img className='max-w-full h-auto'
              src={Supporton}
              alt=""
            />
          </div>
            <div className="md:col-span-8 mt-5">
            <ul >
                <li className='mb-[3%] text-[1.3rem] text-[rgba(219, 253, 255, 1)]'>
                    <p>Access to 100+ mental models that will help you tackle all challenges or tasks in the real world of work</p>
                </li>
                <li className='mb-[3%] text-[1.3rem] text-[rgba(219, 253, 255, 1)]'>
                    <p> 50+ hours of proprietary training content</p>
                </li>
                <li className='mb-[3%] text-[1.3rem] text-[rgba(219, 253, 255, 1)]'>
                    <p> Lifetime access to masterclass recordings, even ones that happen after your batch is finished</p>
                </li>
            </ul>
          </div>
        </div>
      </div> */}

      <div className="pb-12">
        <hr className="hr1 bg-[#BAFFF7] border-t-2 border-white mt-5" />
        <div className="grid grid-cols-12">
          <div className="md:col-span-9"></div>
          <div className="col-span-12 md:col-span-3 justify-end">
            <button
              className="flex gap-12 justify-center mt-4  py-4 w-full border-[0.5px] border-white bg-transparent rounded cursor-pointer ease-linear duration-300"
              onClick={(e) => handleNext("tab-6")}
              style={{ width: "100%", alignContent: "centre" }}
            >
              <div className="txt font-Magistral text-lg font-light text-center text-[#DBFDFF]">
                Next: Who Is This For
              </div>
              <img
                src={Arrow}
                alt=""
                width={20}
                height={30}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </div>
      </div>

      {/* <div className="mt-4 p-2 next d-flex d-inline-flex">
        <h4 className="ww">Next: Who is this for   </h4>
        <img src={Arrow} alt="" width={20} height={30} style={{marginLeft:"8px"}}/>
      </div> */}
    </div>
  );
};

export default Advantage;
