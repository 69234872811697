import mukesh from "../assets/about/Testimonials/mukesh.svg";
import neeti from "../assets/about/Testimonials/neeti.png";
import rolii from "../assets/about/Testimonials/rolii.png";
import BackQuote from "./Testimonial/BackQuote";
import React from "react";
import Slider from "react-slick";
import {useMediaQuery} from "react-responsive";

const TestimonialsCards = () => {
  const isMobile=useMediaQuery({
    query:'(max-width:1200px)'
  });



  const testimonialCards = [
    {
      img_src:mukesh,
      text:"Two years ago, I found a job vacancy, that required only graduates. Despite not having graduated, I got hired over other candidates through storytelling a skill that I learned through Workverse. Their program prioritizes personal as well as professional growth, which helped me develop confidence and become a better version of myself.",
      name:"Mukesh Tiwari",
      col_name:"Udaan India Foundation<br/>(Learner)",
    },
    {
      img_src:neeti,
      text:"The employee I hired from Workverse was quick at learning and knowing how to implement things without needing guidance. She is also good at problem solving, she will definitely keeping adding more value with her work over time.",
      name:"Neeti Kejriwal",
      col_name:"Creative Consultant, Kyoorius Digital<br/>(Employer)",
    },
    {
      img_src:rolii,
      text:"During campus recruitment, employers now give equal importance to academic competence and proficiency in non-technical skills. Having training in 21st century workplace skills like creative problem solving, critical thinking and collaboration can give students a unique edge in the early days of their career.",
      name:"Dr. Rolii Agrawal",
      col_name:"Head, Center for Professional Skills,<br/>Jagran Lakecity University <br/>(Professor)",
    }
  ];

  const testimonialSettings = {
    dots: false,
    infinite: true, // Enable infinite looping
    speed: 3000, // Animation speed in milliseconds (adjust as needed)
    slidesToShow: isMobile ? 1 : 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: false, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed (adjust as needed)
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <div>
      <Slider {...testimonialSettings}>
        { testimonialCards.map((item, index) => (
          <div key={index} className="testimonial-slide " >
            <>
              <div
                className="relative max-w-80 rounded-[11px] mx-4 px-4 pt-16 mt-10  h-[475px] xs:h-[475px] sm:[525px] md:h-[550px] xl:h-[450px] 2xl:h-[450px]"
                style={{
                  background: 'linear-gradient(93.74deg, #021e2994 12.3%, #021e298d 68.5%, #021e298a 90.68%)',
                  fill: 'linear-gradient(132deg, rgba(2, 30, 41, 0.50) 0%, rgba(2, 30, 41, 0.55) 71.70%, rgba(2, 30, 41, 0.54) 100%)  !important',
                  strokeWidth: '1px !important',
                  stroke: 'rgba(210, 210, 210, 0.60)',
                  filter: 'drop-shadow(0px 4px 9px rgba(186, 255, 247, 0.09)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important',
                  backdropFilter: 'blur(5px) !important'
                }}
              >
                <img src={item.img_src} className="h-24 absolute top-[-10%] right-[-15px]" alt="" />
                <div>
                  <BackQuote/>
                  <p className="p-4 xs:text-sm sm:text-base text-white font-light text-base ">
                    <i>{item.text}</i>
                  </p>
                </div>
                <div className="footer-text text-right">
                  <h5 className="text-white font-light text-base pb-4">{item.name}</h5>
                  <p className="ts-text text-[#A1A1A1] font-Magistral text-sm pb-4" dangerouslySetInnerHTML={
                    {__html: item.col_name}
                  }></p>
                </div>
              </div>
            </>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TestimonialsCards;
