import React from 'react'

function BackQuote() {
  return (
    <>
    <svg width="29" id="backquotes" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5312 12.9844V24.5156H0V14.4844C0 11.0781 0.265625 8.4375 0.796875 6.5625C1.32812 4.6875 2.45312 3.15625 4.17188 1.96875C5.89062 0.75 8.34375 0.09375 11.5312 0V4.875C7.78125 5.4375 5.90625 7.76562 5.90625 11.8594V12.9844H11.5312ZM28.1719 12.9844V24.5156H16.6875V14.4844C16.6875 11.0781 16.9375 8.4375 17.4375 6.5625C17.9688 4.6875 19.0938 3.15625 20.8125 1.96875C22.5625 0.75 25.0156 0.09375 28.1719 0V4.875C24.4219 5.4375 22.5469 7.76562 22.5469 11.8594C22.5469 12.1094 22.5625 12.4844 22.5938 12.9844H28.1719Z" fill="#07EDC4"/>
    </svg>
    </>
  )
}

export default BackQuote