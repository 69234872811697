import React from "react";
import img1 from "../../assets/Our Programs/WhatYouLearn/Artboard-1 1.svg";
import img2 from "../../assets/Our Programs/WhatYouLearn/Group 284.png";
import img3 from "../../assets/Our Programs/WhatYouLearn/Group 297.png";
import hack21 from "../../assets/Our Programs/WhatYouLearn/Hack 2 (1).svg";
import hack31 from "../../assets/Our Programs/WhatYouLearn/Hack 3 (1).svg";
import Arrow from "../../assets/Our Programs/arrow.png";

export default function WhatYouLearn({
  handleNext,
}: {
  handleNext: (currTab: string) => void;
}) {
  return (
    <div className="container mx-auto pt-6 md:pt-12">
        <div id="page-header" className='flex flex-col md:flex-row justify-between   py-5  top-20 '>
        <div className='w-full md:w-1/2'>

          <div className='text-[#31e2ed] mb-5 text-left'>
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className='col-span-12 md:col-span-3 lg:col-span-6 text-right'>
          <button style={{background:"linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)"}} className='shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white '>
            <div className='whitespace-nowrap' style={{border:'3px solid black',borderRadius:'4px',padding: '11px',paddingLeft: '25px',paddingRight: '25px',margin:'3px',marginRight:'-2px',marginLeft:'-1px',textShadow:" 0 0 10px #fff"}}>Apply Now {'>>'}</div>
          </button>
        </div>
      </div>

      <div className="w-full md:w-3/4">
        <h2 className="text-[#dbfdff] font-bold ">
          Soft skills, mental models & workplace hacks that enhance your ability
          to apply technical skills
        </h2>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-8 mt-5">
          <p className=" text-[#dbfdff] ">
            Smart Fellowship trains your brain to apply 9 superpower soft
            skills, 30+ mental models and workplace hacks. This experience along
            with a strong technical foundation will make you unstoppable at
            work!
          </p>

          <p className=" text-[#dbfdff] ">
            The superpower soft skills you will master
          </p>
          <div className="flex flex-wrap gap-4 mt-8">
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
                Creative Problem Solving
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
                Negotiation
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
                Storytelling
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
              Entrepreneurial Mindset
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
              First Principles Thinking
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
              Emotional Intelligence
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
              Collaboration
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
              Sharp Remote Communication
              </p>
            </button>
            <button className="btnw">
              <p className=" text-[#07edc4] px-6 py-3 font-Magistral text-base text-center p-1 mb-0">
              Productivity Management
              </p>
            </button>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4">
          <img className="img-fluid" src={img1} alt="" />
        </div>
      </div>

      <div className=" mt-5">
        <h2 className="font-Magistral font-bold text-[#31e2ed] mb-4 ">
          01. Mental Models
        </h2>
        <div className="md:w-4/5">
          <p className=" text-[#dbfdff]  mb-2">
            Mental models are ways of thinking / analyzing / deciding that help
            you use these superpower soft skills in every situation at work.
            Whether it's dealing with tough clients, smooth talking to get
            someone to help you, collaborating with different stakeholders, or
            finding smart ways to solve complex problems - these mental models
            will help you get to your goal.
          </p>

          <p className="  text-[#dbfdff] ">
            We will train your brain to apply 30+ mental models. Here are 2
            examples!
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-8 mt-4">
        <div className="col-span-12 md:col-span-6">
          <p className="uppercase font-Magistral text-sm font-bold text-[#31e2ed] mb-2">
            Thought Journey to identify the root cause of a problem
          </p>
          <div className="img-wrap p-3 aspect-[3/2]">
            <img className="w-full h-auto mt-5 img-fluid" src={img2} alt="" />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <p className="uppercase div-text font-Magistral text-sm font-bold text-[#31e2ed] mb-2">
            Thought Journey to Negotiate
          </p>
          <div className="img-wrap  p-3 aspect-[3/2]">
            <img
              className="img w-full h-auto mt-5 img-fluid"
              src={img3}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="mental font-Magistral  font-bold text-[#31e2ed] mb-8">
          02. Workplace Hacks
        </h2>
        <div className="md:col-span-8">
          <p className="text-[#dbfdff]  mb-2">
            Why waste time figuring it out, when we’ve done it for you?
          </p>

          <p className="text-[#dbfdff] ">
            After surveying 1000+ successful professionals, we have developed
            ‘Hacks’ for the most common problems or tasks every professional has
            to perform. Use them in the simulation, so you’re a pro at them when
            you enter the real world of work.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-12 mt-5">
        <div className="col-span-12 md:col-span-6">
          <img src={hack21} alt="" className="img-fluid w-full h-auto" />
        </div>
        <div className="col-span-12 md:col-span-6">
          <img src={hack31} alt="" className="img-fluid w-full h-auto" />
        </div>
      </div>

      <div className="mt-8">
        <h2 className="mental font-Magistral  font-bold text-[#31e2ed] mb-8">
          03. Pre-Placement Training
        </h2>
        <div className="md:col-span-6">
          <ul className="list-disc  space-y-4  ml-8 mb-[3vh] text-[#dbfdff]">
            <li className="text-base text-[#dbfdff] lg:text-[22px]">
              Career counseling
            </li>
            <li className="text-base text-[#dbfdff] lg:text-[22px]">
              Interview tips
            </li>
            <li className="text-base text-[#dbfdff] lg:text-[22px]">
              How to leverage the Smart Fellowship experience to <br /> convince
              recruiters you’re the right fit
            </li>
          </ul>
        </div>
      </div>

      <div className="pb-12">
        <hr className="hr1 bg-[#BAFFF7] border-t-2 border-white mt-5" />
        <div className="grid grid-cols-12">
          <div className="md:col-span-9"></div>
          <div className="col-span-12 md:col-span-3 justify-end">
            <button
              className="flex gap-12 justify-center mt-4  py-4 w-full border-[0.5px] border-white bg-transparent rounded cursor-pointer ease-linear duration-300"
              onClick={(e) => handleNext("tab-3")}
              style={{ width: "100%", alignContent: "centre" }}
            >
              <div className="txt font-Magistral text-lg font-light text-center text-[#DBFDFF]">
                Next: How You Learn
              </div>
              <img
                src={Arrow}
                alt=""
                width={20}
                height={30}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
