import React from "react";
import Workplace from "../../assets/Our Programs/Placement/Workverse.svg";
import Workverse from "../../assets/Our Programs/Placement/Workplace.svg";
import Joboffer from "../../assets/Our Programs/Placement/JobOffers.svg";
import Arrow from "../../assets/Our Programs/arrow.png";

const Placement = ({
  handleNext,
}: {
  handleNext: (currTab: string) => void;
}) => {
  return (
    <div className="container mx-auto pt-6 md:pt-12">
      <div
        id="page-header"
        className="flex flex-col md:flex-row justify-between   py-5  top-20 "
      >
        <div className="w-full md:w-1/2">
          <div className="text-[#31e2ed] mb-5 text-left">
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className="col-span-12 md:col-span-3 lg:col-span-6 text-right">
          <button
            style={{
              background:
                "linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)",
            }}
            className="shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white "
          >
            <div
              className="whitespace-nowrap"
              style={{
                border: "3px solid black",
                borderRadius: "4px",
                padding: "11px",
                paddingLeft: "25px",
                paddingRight: "25px",
                margin: "3px",
                marginRight: "-2px",
                marginLeft: "-1px",
                textShadow: " 0 0 10px #fff",
              }}
            >
              Apply Now {">>"}
            </div>
          </button>
        </div>
      </div>
      <h2 className=" text-[#dbfdff] mb-4">
        <b>Be the chosen 1 amongst 250+ applicants</b>
      </h2>

      <p className="text-[#DBFDFF] text-justify mb-4">
        Smart Fellows get a unique advantage because you can think, solve and{" "}
        communicate ‘smarter’ than others in the sea of applicants. You have
        what cannot be automated.
      </p>
      <p className="text-[#DBFDFF]  text-justify mb-4">
        After successfully completing your learning journey, we pair you with
        new age enterprises looking for ambitious hustlers in their finance
        function.
      </p>

      <div className="mt-[5vh]">
        <h2 className="font-Magistral font-bold text-[#31e2ed] mb-4">
          01. You graduate with your unique Workplace Intelligence Profile
        </h2>
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 md:col-span-6">
            <p className="text-[#DBFDFF]  text-justify ">
              {" "}
              The Workplace Intelligence Profile (WIP) is like an assessment
              report based on our observations of your actions, decisions and
              communications while you roleplay in the simulation. The
              observations are computed using an evidence-based behavioral
              assessment rubric.
            </p>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="w-full h-full">
              <img
                src={Workplace}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[5vh]">
        <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
          02. Recruiters get your WIP
        </h2>
        <div className="grid grid-cols-12  gap-8 ">
          <div className="col-span-12 md:col-span-6">
            <p className=" text-[#DBFDFF]  text-justify">
              {" "}
              The Workplace Intelligence Profile (WIP) provides employers with
              an understanding of how you would apply 9 superpower soft skills
              in a diverse range of workplace situations. Think of this as a new
              age ‘certification’ which is more valuable than just degrees.
            </p>
          </div>
          <div className="col-span-12 md:col-span-6">
            <img
              src={Workverse}
              alt=""
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="mt-[5vh]">
        <h2 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
          03. Choose your Dream Job Offer
        </h2>
        <div className="grid grid-cols-12 gap-8 ">
          <div className="col-span-12 md:col-span-6">
            <p className=" text-justify text-[#DBFDFF]">
              {" "}
              We schedule interviews for you with our partner employers and
              guide you to ace the interview process. So be prepared to make a
              tough choice between at least 2 exciting offers.
            </p>
          </div>
          <div className="col-span-12 md:col-span-6">
            <img src={Joboffer} alt="" />
          </div>
        </div>
      </div>

      <div>
        <div
          className="shadow-[0px_0px_5px_0px_rgba(186, 255, 247, 0.30)] rounded-2xl p-4 mt-5"
          style={{
            background:
              "linear-gradient(219deg, rgba(217, 217, 217, 0.30000001192092896) 0%, rgba(217, 217, 217, 0.30000001192092896) 100%)",
          }}
        >
          <h5 style={{ color: "white" }}>TERMS AND CONDITIONS</h5>
          <p style={{ color: "white" }}>
            Placement support is only for those candidates who have successfully
            completed the program with full attendance, and receive the minimum
            required score on the WIP. You are free to reject all offers;
            however, in that scenario, you will have to pay us the placement
            support fee if you receive a minimum of 2 offers from partner
            employers. You can continue using the shareable version of the WIP
            should you choose to seek job offers outside of the fellowship.
          </p>
        </div>
      </div>

      <div className="pb-12">
        <hr className="hr1 bg-[#BAFFF7] border-t-2 border-white mt-5" />
        <div className="grid grid-cols-12">
          <div className="md:col-span-9"></div>
          <div className="col-span-12 md:col-span-3 justify-end">
            <button
              className="flex gap-12 justify-center mt-4  py-4 w-full border-[0.5px] border-white bg-transparent rounded cursor-pointer ease-linear duration-300"
              onClick={(e) => handleNext("tab-5")}
              style={{ width: "100%", alignContent: "centre" }}
            >
              <div className="txt font-Magistral text-lg font-light text-center text-[#DBFDFF]">
                Next: Advantages
              </div>
              <img
                src={Arrow}
                alt=""
                width={20}
                height={30}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Placement;
