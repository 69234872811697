import { Button } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import "./button.css"
import { styled } from '@mui/material/styles';

const CustomBlueButton = styled(Button)(({ theme }) => ({
    width: 300,
    color: theme.palette.success.main,
    '&': {
        background: "rgb(136,218,213)",
        background: "linear-gradient(0deg, rgba(136,218,213,1) 0%, rgba(95,206,200,1) 100%)",
        paddingRight: "5px",
        fontSize: "1rem",
        textTransform: "none",
        color: "#fff",
        borderRadius: "20px",
        width: "100%",
        fontFamily: "'century-gothic', sans-serif",
        fontWeight: "700",
        fontStyle: "normal"
    },
    '&:hover': {
        boxShadow: "13px 4px 25px -8px rgba(0,0,0,0.48);"
    },
    '@media(max-width: 600px)': {
      fontSize: "0.7rem"
    }
  }));

const BlueButton = ({text, onClick, variant, disabled, type, iconPos ='after'}) => {
  return (
    <CustomBlueButton onClick={onClick} type={type} variant='outlined' className='blue-button'>
          {/*iconPos is a position to set icon before the text of after by default it's after  */}
          {iconPos === 'before' && <ArrowBackIosIcon />}
            {/* text for the button */}
            {text}
            {/* Arrow Icon */}

            {iconPos === 'after' && <ArrowForwardIosIcon className='blue-button-arrow' />}
    </CustomBlueButton>
  )
}

export default BlueButton