import React from 'react'
import SectionOne from '../components/For-Employers/SectionOne'
import SectionTwo from '../components/For-Employers/SectionTwo'
import { useMediaQuery } from 'react-responsive'
import MobileForEmp from '../components/For-Employers/MobileForEmp'
import Header from '../components/header'

function ForEmployers() {
  const isMobile=useMediaQuery({
    query:'(max-width:900px)'
  })
  if(isMobile){
    return (
    <div className='w-full gradient overflow-x-hidden '>
      <Header/>
      <MobileForEmp/>
    </div>)
  }
  return (
    <div className='w-full gradient overflow-x-hidden '>
      <Header/>
      <SectionOne/>
      <SectionTwo/>
      <section className='h-24'>
      </section>
    </div>
  )
}

export default ForEmployers
