import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import sectionTwo_img1 from "../../assets/ForEmployers/SectionTwo/part2-img1.svg";
import sectionTwo_img2 from "../../assets/ForEmployers/SectionTwo/part2-img2.svg";
import sectionTwo_img3 from "../../assets/ForEmployers/SectionTwo/part2-img3.svg";
import sectionTwo_img4 from "../../assets/ForEmployers/SectionTwo/part2-img4.svg";
import mentalModel from "../../assets/ForEmployers/SectionTwo/mental-model.svg";
import hacksImg from "../../assets/ForEmployers/SectionTwo/hacks.svg";
import peoples from "../../assets/ForEmployers/SectionTwo/peoples.svg";
import inner from "../../assets/ForEmployers/SectionTwo/inner.svg";
import outer from "../../assets/ForEmployers/SectionTwo/outer.svg";
import financeImg from "../../assets/section4/finance.png";
import itImg from "../../assets/section4/it.png";
import mediaImg from "../../assets/section4/media.png";
import ContactForm from "./ContactForm";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TestimonialsCards from "../TestimonialsCards";
gsap.registerPlugin(ScrollTrigger);

function SectionTwo() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",
    centerMode: true,
    pauseOnHover: false,
  };

  const div1Ref = useRef(null);
  const div2Ref = useRef(null);

  const div3Ref = useRef(null);
  const div4Ref = useRef(null);

  const div5Ref = useRef(null);
  const div6Ref = useRef(null);

  const div7Ref = useRef(null);
  const div8Ref = useRef(null);

  const div9Ref = useRef(null);
  const div10Ref = useRef(null);

  const div11Ref = useRef(null);
  const div12Ref = useRef(null);

  const div13Ref = useRef(null);
  const div14Ref = useRef(null);

  const [showDiv, setShowDiv] = useState(false);

  useLayoutEffect(() => {
    let tl = gsap.timeline();
    gsap.fromTo(
      div1Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div1Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div2Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div2Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );
    gsap.fromTo(
      div3Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div3Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div4Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div4Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div5Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div5Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div6Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div6Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div7Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div7Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div8Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div8Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div9Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div9Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div10Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div10Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div11Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div11Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div12Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div12Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div13Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div13Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div14Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div14Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );
    tl.to(
      ".emp-section-one-img1",
      {
        rotate: 360,
        duration: 30,
        ease: "linear",
        repeat: -1,
        yoyo: false,
        delay: 1,
      },
      "<"
    ).to(
      ".emp-section-one-img2",
      {
        rotate: -360,
        duration: 30,
        ease: "linear",
        repeat: -1,
        yoyo: false,
        delay: 1,
      },
      "<"
    );
    gsap.to("#sec6", {
      scrollTrigger: {
        trigger: "#sec6",
        start: "top 90%",
        // markers: true,
        scrub: true,
      },
      ease: "linear",
      opacity: 1.5,
      duration: 0.3,
      y: 100,
    });

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="container mx-auto mt-16 overflow-x-hidden bg-cover bg-no-repeat">
      <section className="sec min-h-[50vh]" id="sec1">
        <div ref={div1Ref} className="grid grid-cols-12 mt-5 pt-5">
          <div className="col-span-12 ">
            <h2 className="part-2-text font-light text-[#BAFFF7] text-center">
              <span className="capitalize">The smart fellowship</span> is a{" "}
              <span className="font-bold capitalize">workplace simulation</span>{" "}
              and <span className="font-bold capitalize">role play</span> based
              program where graduates master 9 soft skills. These superpowers
              enhance their abiliy to add value to your business.{" "}
            </h2>
            <h2 className="part-2-text  font-light text-[#BAFFF7] text-center pt-5">
              Here is how it <span className="font-bold ">works!</span>
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-12 justify-center items-center">
          <div
            ref={div2Ref}
            className="col-span-6 px-5 py-5 justify-center items-center"
          >
            <img
              src={sectionTwo_img1}
              className="img-fluid w-full h-auto"
              alt=""
            />
          </div>
          <div
            ref={div3Ref}
            className="col-span-6 px-5 justify-center font-light text-[#BAFFF7] items-center part-2-text py-5"
          >
            <h1 className="opacity-50 text-[6rem] underline part-2-text-number">
              01
            </h1>
            <h3 className="">
              We scout for ambitious graduates across different streams.
            </h3>
          </div>
        </div>
      </section>

      <section className="sec" id="sec2">
        <div className="grid grid-cols-12 justify-center items-center">
          <div
            ref={div4Ref}
            className="col-span-6 px-5 justify-center items-center part-2-text py-5"
          >
            <h1 className=" opacity-50 text-[6rem] underline part-2-text-number">
              02
            </h1>
            <h3 className="">
              Pre-assessment for technical skills and testing learnability of
              soft skills.
            </h3>
          </div>
          <div
            ref={div5Ref}
            className="col-span-6 px-5 py-5 justify-center items-center"
          >
            <img
              src={sectionTwo_img2}
              className="img-fluid w-full h-auto"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="sec" id="sec3">
        <div className="grid grid-cols-12  justify-center ">
          <div ref={div6Ref} className="col-span-6">
            <img
              src={sectionTwo_img3}
              className="image-fluid w-full h-auto"
              alt=""
            />
          </div>
          <div
            ref={div7Ref}
            className="col-span-6 px-5 justify-center font-light text-[#BAFFF7] part-2-text py-5"
          >
            <h1 className=" opacity-50 text-[6rem] underline part-2-text-number">
              03
            </h1>
            <h3 className="">
              Before they join you, Smart Fellows 'work' as a core team member
              of an imaginary company for 6 weeks.
            </h3>
          </div>
        </div>
      </section>
      <section
        className="sec flex justify-center items-center min-h-[100vh]"
        id="sec4"
      >
        <div className="grid grid-cols-12 justify-center items-center">
          <div
            ref={div8Ref}
            className="col-span-4 shrink-0 px-5 justify-center items-center font-light text-[#BAFFF7] part-2-text py-5"
          >
            <h1 className=" opacity-50 text-[6rem] underline part-2-text-number">
              04
            </h1>
            <h3 className="">
              You get to hire based on data that really matters!{" "}
            </h3>
          </div>
          <div
            ref={div9Ref}
            className="col-span-8 flex-grow px-5 py-5 justify-center items-center"
          >
            <img
              src={sectionTwo_img4}
              className="img-fluid w-full h-auto"
              alt=""
            />
            <h5 className="text-center mt-4" style={{ color: "#009B8C" }}>
              As they ‘work’ in the simulation, we observe them to give you an
              insight into how they think, solve & communication across
              different contexts
            </h5>
          </div>
        </div>
      </section>
      <section
        className="sec min-h-[30vh]"
        id="sec5"
        style={{ height: "200px" }}
      >
        <div ref={div10Ref} className="grid grid-cols-12 justify-center">
          <div className="md:col-span-8">
            <h3 className="primary-text text-center">
              <span className="text-[#bafff7]">Smart Fellows</span>{" "}
              <span className="wont text-white font-light leading-[55px]">
                {" "}
                won't ask stupid questions. Instead, they come with superpowers
                that will empower your business to thrive!
              </span>
            </h3>
          </div>
        </div>
      </section>
      <section className="sec" id="sec6">
        <div className="grid grid-cols-12 my-5 reduced-top-margin">
          <div
            ref={div11Ref}
            className="md:col-span-6 animate-me"
            style={{ marginTop: "5%" }}
          >
            <ul className="list-group">
              <li className="list-group-item my-5  border-0 bg-transparent flex justify-start items-center">
                <div className="step-icon">
                  <img
                    src={mentalModel}
                    alt=""
                    className="img-fluid w-full h-auto p-1"
                  />
                </div>
                <span className="px-5">
                  <h5 className="font-light text-[#bafff7]">
                    100+ Mental Models to solve almost any problem
                  </h5>
                </span>
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item my-5  border-0 bg-transparent flex justify-start items-center">
                <div className="step-icon">
                  <img
                    src={hacksImg}
                    alt=""
                    className="img-fluid w-full h-auto p-1"
                  />
                </div>
                <span className="px-5">
                  <h5 className="font-light text-[#bafff7]">
                    200+ Hacks to get work done
                  </h5>
                </span>
              </li>
            </ul>
          </div>

          <div
            className="relative md:col-span-6 px-5 justify-center items-center bg-no-repeat bg-contain bg-center bg-fixed"
            id="empSectionOneRight"
            style={{
              backgroundImage: `url(${peoples})`,
              backgroundPosition: "",
            }}
          >
            <img
              src={inner}
              className="absolute   lg:ml-[40px] lg:mt-[-40px] xl:ml-[70px] xl:mt-[-80px] "
              alt=""
              style={{
                
                animation: "rotateCounterclockwise 10s linear infinite",
              }}
            />
            <img
              src={outer}
              className="absolute md:ml[30px] lg:mt-[-80px] xl:ml-[30px] xl:mt-[-120px]"
              alt=""
              style={{
              
                animation: "rotateCounterclockwise 10s linear infinite",
              }}
            />
          </div>
          {/* </div> */}
        </div>
      </section>

      <section className="sec" style={{ height: 200, marginTop: "30%" }}>
        <div ref={div12Ref} className="grid grid-cols-12 justify-center ">
          <div className="md:col-span-2"></div>
          <div className="col-span-12 md:col-span-10 text-center ">
            <h3 className="text-white font-light">
              <span className="text-[#bafff7] font-bold">Storylines</span> of
              the simulation are domain specific.
            </h3>
            <h3 className="text-white font-light">
              Smart fellows adapt applications of non-technical skills to their
              specific domains.
            </h3>
          </div>
        </div>
      </section>

      <section className="sec">
        <div ref={div13Ref} className=" justify-center text-center my-5">
          <Slider {...settings}>
            <div className="">
              <a href="/our-programs"><img src={financeImg} alt="" className="img-fluid w-full h-auto" /></a>
            </div>
            <div className=" ">
              <img src={itImg} alt="" className="w-full h-auto img-fluid" />
            </div>
            <div className=" ">
              <img src={ mediaImg} alt="" className="w-full h-auto img-fluid" />
            </div>
          </Slider>
        </div>
      </section>

      <section className="sec mt-16">
        <div ref={div14Ref} className="">
          <TestimonialsCards />
        </div>
      </section>

      <section>
        <div className="w-full mb-5">
          <ContactForm />
        </div>
      </section>
    </div>
  );
}

export default SectionTwo;
