import React, { useEffect, useRef } from "react";
import wipHIW from "../assets/wip/wip-how-it-works.svg";
import wipWhy from "../assets/wip/wip-why.svg";
import wipContext1 from "../assets/wip/wip-context-1.svg";
import wipContext2 from "../assets/wip/wip-context-2.svg";
import gsap from "gsap";
import wipslide1 from "../assets/wip/WIP 3.svg";
import wipslide2 from "../assets/wip/wipslide2.svg";
import wipslide3 from "../assets/wip/wipslide3.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/header";

function WIP() {
  const scannerRef = useRef(null);
  useEffect(() => {
    const scanner = scannerRef.current;
    const tl = gsap.timeline({ repeat: -1, yoyo: true, repeatDelay: 0 });
    tl.fromTo(
        scanner,
        { x: "-70%" },
        { x: "3310%", duration: 6, ease: 'power1.inOut', },
    );

    return () => {
        tl.kill(); // Kill the timeline on unmount to prevent memory leaks
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    cssEase: "linear",
    centerMode: true,
    pauseOnHover: true,
  };

  const div1Ref = useRef(null);
  const div2Ref = useRef(null);

  const div3Ref = useRef(null);
  const div4Ref = useRef(null);

  const div5Ref = useRef(null);
  const div6Ref = useRef(null);

  const div7Ref = useRef(null);
  const div8Ref = useRef(null);

  const div9Ref = useRef(null);
  const div10Ref = useRef(null);

  const div11Ref = useRef(null);
  const div12Ref = useRef(null);

  const div13Ref = useRef(null);
  const div14Ref = useRef(null);

  const div15Ref = useRef(null);
  const div16Ref = useRef(null);

  const div17Ref = useRef(null);
  const div18Ref = useRef(null);

  const div19Ref = useRef(null);
  const div20Ref = useRef(null);

  const div21Ref = useRef(null);
  const div22Ref = useRef(null);

  const div23Ref = useRef(null);
  const div24Ref = useRef(null);

  const div25Ref = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      div1Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div2Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
        delay: 0.5,
      }
    );

    gsap.fromTo(
      div3Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
        delay: 1,
      }
    );

    gsap.fromTo(
      div4Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        delay: 1.5,
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div5Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div5Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div6Ref.current,
      {
        // y: "0%",
        scale: 2.5,
        opacity: 0, // Starting positio (e.g., no horizontal movement)
      },
      {
        scrollTrigger: {
          trigger: div6Ref.current,
          start: "top bottom", // Set the start point of the animation trigger
          end: "bottom top", // Set the end point of the animation trigger
          scrub: true, // Enable scrubbing for smooth animation
          // pin: true,
        },
        // x: "100%",
        // y: "100%",
        opacity: 1,
        duration: 1,
        scale: 1,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div7Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div7Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div8Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div8Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div9Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div9Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div10Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div10Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div11Ref.current,
      {
        // y: "0%",
        scale: 2.5,
        opacity: 0, // Starting positio (e.g., no horizontal movement)
      },
      {
        scrollTrigger: {
          trigger: div11Ref.current,
          start: "top bottom", // Set the start point of the animation trigger
          end: "bottom top", // Set the end point of the animation trigger
          scrub: true, // Enable scrubbing for smooth animation
          // pin: true,
        },
        // x: "100%",
        // y: "100%",
        opacity: 1,
        duration: 1,
        scale: 1,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div12Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div12Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div13Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div13Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div14Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div14Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div15Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div15Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div16Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div16Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div17Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div17Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div18Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div18Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div19Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div19Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div20Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div20Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div21Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div21Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div22Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div22Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div23Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div23Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div24Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div24Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      div25Ref.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: div25Ref.current,
          start: "top 110%",
          end: "bottom 50%",
          scrub: true,
        },
        // x: "100%",
        // y: "100%",
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    return () => {
        gsap.killTweensOf(div1Ref.current);
        gsap.killTweensOf(div2Ref.current);
        gsap.killTweensOf(div3Ref.current);
        gsap.killTweensOf(div4Ref.current);
        gsap.killTweensOf(div5Ref.current);
        gsap.killTweensOf(div6Ref.current);
        gsap.killTweensOf(div7Ref.current);
        gsap.killTweensOf(div8Ref.current);
        gsap.killTweensOf(div9Ref.current);
        gsap.killTweensOf(div10Ref.current);
        gsap.killTweensOf(div11Ref.current);
        gsap.killTweensOf(div12Ref.current);
        gsap.killTweensOf(div13Ref.current);
        gsap.killTweensOf(div14Ref.current);
        gsap.killTweensOf(div15Ref.current);
        gsap.killTweensOf(div16Ref.current);
        gsap.killTweensOf(div17Ref.current);
        gsap.killTweensOf(div18Ref.current);
        gsap.killTweensOf(div19Ref.current);
        gsap.killTweensOf(div20Ref.current);
        gsap.killTweensOf(div21Ref.current);
        gsap.killTweensOf(div22Ref.current);
        gsap.killTweensOf(div23Ref.current);
        gsap.killTweensOf(div24Ref.current);
        gsap.killTweensOf(div25Ref.current);
    }
  }, []);

  return (
    <div className="gradient overflow-x-hidden">
      <Header />
      <div className="w-full container mx-auto">
        {/* wip intro  */}
        <section className="wip-sec" id="wipHeader">
          <div ref={div1Ref} className=" justify-center">
            <h1 className="text-[#bafff7] text-center font-bold">
              Workplace Intelligence Profile
            </h1>
          </div>
          <div ref={div2Ref} className=" justify-center mb-8">
            <p className="text-white text-center font-light">
              The certification that really matters!
            </p>
          </div>
          <div ref={div3Ref} className=" mt-md-5 justify-center">
            <Slider {...settings}>
              <div className="w-full">
                <img
                  src={wipslide1}
                  className="img-fluid w-full h-auto lg:h-[60vh]  wip-slide-img"
                  alt=""
                />
              </div>
              <div className="w-full ">
                <img
                  src={wipslide2}
                  className="img-fluid w-full h-auto lg:h-[60vh]  wip-slide-img"
                  alt=""
                />
              </div>
              <div className="w-full mx-auto ">
                <img
                  src={wipslide3}
                  className="img-fluid w-full h-auto  lg:h-[60vh] wip-slide-img"
                  alt=""
                />
              </div>
            </Slider>
          </div>
        </section>

        {/* what section  */}

        <section className="wip-sec" id="wipWhat">
          <div
            ref={div4Ref}
            className="grid grid-cols-12 mt-2 md:mt-5 mb-0 md:my-5 py-4 justify-center text-center"
          >
            <div className="md:col-span-2"></div>
            <div className="col-span-12 md:col-span-8">
              <p className="text-white font-light text-center">
                In the world of work where a candidate's 'smartness' matters as
                much as their knowledge, how does one really get an insight into
              </p>
              <p className="primary-text font-bold text-center">
                How they think, solve & communicate?{" "}
              </p>
            </div>
            <div className="md:col-span-2"></div>
          </div>
          <div
            ref={div5Ref}
            className="grid grid-cols-12 justify-center text-center "
          >
            <div className="md:col-span-2"></div>
            <div className="col-span-12 md:col-span-8 ">
              <p className="mb-1 md:mb-5 pb-4 text-white font-light text-center">
                The Workplace Intelligence Profile (WIP) provides an
                understanding of how a candidate applies the 9 superpower soft
                skills in a diverse range of workplace situations in the context
                of their domain.
              </p>
              <p className="mb-1 md:mb-5 text-white font-light text-center">
                WIP gives a hiring advantage to candidates and employers both
                since they have research-backed insights that let the brightest
                talent stand out.
              </p>
            </div>
            <div className="md:col-span-3"></div>
          </div>
        </section>

        {/* How it works section  */}

        <section className="wip-sec" id="wipHow">
          <div
            ref={div6Ref}
            className=" mt-5 md:mt-5 pt-1 md:pt-5  text-center"
          >
            <h2 className="text-[#bafff7] font-bold">How it Works!</h2>
          </div>
          <div ref={div7Ref} className=" py-5">
            <div style={{ position: "relative", overflow: "hidden" }}>
              <img src={wipHIW} className="img-fluid w-full h-auto" alt="" />
              <div
                ref={scannerRef}
                style={{
                  width: "3%",
                  height: "100%",
                  background: "#07EDC4", // Customize the scanning line appearance
                  position: "absolute",
                  bottom: "0",
                  // border: "2px solid black",
                  // borderRadius:"5px",
                  boxShadow: "0px 0 100px #07EDC4",
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-12 justify-center text-center">
            <div className="md:col-span-2"></div>
            <div className="col-span-9 md:col-span-8">
              <p
                ref={div8Ref}
                className="text-center text-white font-light mb-4  md:mb-5 md:py-5"
              >
                The SMART fellowship puts learners in the center of 100+ real
                life workplace scenarios including solving complex problems,
                applying 'common sense', collaborating with multiple
                stakeholders, communicating effectively through different
                mediums, and using the required tools to be tech-savvy.
              </p>
              <p ref={div9Ref} className="text-center text-white font-light">
                Each learner's behavior is closely monitored based on our
                research-backed assessment matrix.
              </p>
              <p
                ref={div10Ref}
                className="text-center text-white font-light  my-4 md:my-5 py-5"
              >
                Once learners meet the benchmark to become Smart Fellows, we
                leverage their WIP to enable job access in aspirational and
                meaningful roles.
              </p>
            </div>
            <div className="md:col-span-2"></div>
          </div>
        </section>

        {/* Why section  */}

        <section className="wip-sec" id="wipWhy">
          <div ref={div11Ref} className="text-center md:py-5">
            <h2 className="text-[#bafff7] font-bold">Why this is a must-have for recruiting</h2>
          </div>
          <div ref={div12Ref} className=" justify-center pt-4">
            <h2 className="text-[#bafff7] font-bold">Accuracy</h2>
          </div>
          <div ref={div13Ref} className=" justify-center">
            <p className="text-white font-light">
              Between scanning resumes and taking interviews, recruiters get to
              interact with a candidate during a short window of only 30-60
              minutes.
            </p>
          </div>
          <div ref={div14Ref} className=" py-4 md:py-5">
            <p className="text-white fw-light">
              This is not enough to get a true insight into how they would
              contextualize and apply their innate intelligence for delivering
              outcomes at work.
            </p>
          </div>
          <div ref={div15Ref} className=" flex justify-center text-center">
            <img src={wipWhy} alt="" className="img-fluid" />
          </div>
          <div ref={div16Ref} className="md:my-5 py-3">
            <p className="text-white font-light">
              Whereas, The inputs in our WIP are based on the learner's behavior
              for 50+ hours and across 100+ use cases.
            </p>
          </div>
        </section>

        <section className="wip-sec" id="wipContext">
          <div ref={div17Ref} className="pt-4">
            <h2 className="text-[#bafff7] font-bold">Context</h2>
          </div>
          <div ref={div18Ref} className="md:mb-5">
            <p className="text-white font-light">
              Standard psychometrics do not help conclude on how a candidate
              would apply their generalist intelligence / soft skills in the
              context of India specific, inter-connected & multi-layered
              workplace scenarios.
            </p>
          </div>
          <div
            ref={div19Ref}
            className="grid grid-cols-12 justify-center w-full text-center my-2 md:my-5"
          >
            <div className="col-span-12 md:col-span-12  justify-center w-full">
              <img src={wipContext1} alt="" className="img-fluid" />
            </div>
          </div>
          <div
            ref={div20Ref}
            className="grid grid-cols-12 align-center  mb-5 md:my-5"
          >
            <div className="col-span-12 md:col-span-8 p-4">
              <p className="text-white font-light">
                Our assessment metric is designed to observe the cause effect of
                a learner's actions, reactions and decisions to give a more
                qualitative insight into their behaviors, attitudes and approaches
                at work.
              </p>
            </div>
            <div ref={div21Ref} className="col-span-12 md:col-span-4">
              <img
                src={wipContext2}
                alt=""
                className="img-fluid w-full h-auto"
              />
            </div>
          </div>
        </section>

        <section id="wipScience mb-16">
          <div ref={div22Ref} className="pt-4">
            <h2 className="text-[#bafff7] font-bold">Science</h2>
          </div>

          <div
            ref={div23Ref}
            className="flex w-10/12 justify-end md:justify-start mb-8"
          >
            <div className="col-span-12  md:col-span-10 pt-5">
              <div
                className=" p-5 lg:p-16 rounded-2xl"
                style={{
                  background:
                    "linear-gradient(93.74deg, rgba(2, 30, 41, 0.58) 12.3%, rgba(2, 30, 41, 0.551528) 68.5%, rgba(2, 30, 41, 0.54) 90.68%)",
                  boxShadow: "0 4px 9px #bafff717, 0 0 4px #07edc44d",
                }}
              >
                <h3 className="text-white font-light px-3 py-3 opacity-70">
                  Simulated learning experiences allow learners to explore
                  complex and challenging scenarios that may be difficult to
                  replicate in traditional learning environments. They promote
                  critical thinking, problem-solving, and decision-making skills
                  by providing a platform for learners to analyze, synthesize,
                  and apply knowledge in a realistic context. 
                </h3>
                <p className="text-white py-3 font-bold text-left md:text-right">
                  <i>
                    Excerpt taken from research conducted by <br />
                    Cook, D. A., & Triola, M. M. (2009)
                  </i>
                </p>
              </div>
            </div>

          </div>

          <div ref={div24Ref} className="flex w-full  justify-end ">
            <div className=" w-10/12 pt-5">
              <div
                style={{
                  background:
                    "linear-gradient(93.74deg, rgba(2, 30, 41, 0.58) 12.3%, rgba(2, 30, 41, 0.551528) 68.5%, rgba(2, 30, 41, 0.54) 90.68%)",
                  boxShadow: "0 4px 9px #bafff717, 0 0 4px #07edc44d",
                }}
                className=" p-5 lg:p-16  rounded-2xl"
              >
                {/* <Qoute /> */}
                <h3 className="text-white font-light px-3 py-3 science-card-text opacity-70">
                  Behavioral analysis enables employers to evaluate candidates'
                  problem-solving abilities, interpersonal skills, and
                  adaptability, which are crucial for success in dynamic and
                  changing work environments. By assessing candidates' past
                  behaviors, employers can identify those who have demonstrated
                  the desired competencies and are more likely to excel in
                  challenging situations. 
                </h3>
                <p className="text-white py-3 font-bold text-left md:text-right">
                  <i>
                    Hurtz, G. M., & Donovan, J. J. (2000).
                    <br />
                    Personality and job performance: The Big Five revisited.
                    Journal of Applied Psychology
                  </i>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className='h-24'>
        </section>
    </div>
  );
}

export default WIP;
