import React from 'react'
import first from "../../assets/logo.png"
// import second from "../../assets/logo/inner-ring.svg"
// import third from '../../assets/logo/out-ring.svg'

interface LogoProps{
    scale:number;
    classNames:string;
}

function Logo({scale ,classNames }:LogoProps) {
  return (
      <div className={`relative  mt-4  ${scale} ${classNames}`} >
        <ul className="list-unstyled flex flex-1 justify-center items-center sizeLogo">
          <li className="absolute scale-[2] mt-[-18px]"><img src={first} alt="" width={120}></img>
          </li>
        </ul>
      </div>
      //    <ul className="list-unstyled flex flex-1 justify-center items-center sizeLogo">
      //               {/* <li className="absolute scale-[3] mt-[-60px] wv-logo-animate">*/}
      //               {/*    <img src={third} alt=""></img>*/}
      //               {/*</li>*/}
      //
      //               {/*<li className="absolute scale-[3] mt-[-60px] wv-logo-animate" >*/}
      //               {/*    <img src={second} alt=""></img>*/}
      //               {/*</li>*/}
      //               <li className="absolute mt-[-60px]" >
      //                   <img src={first} alt="" width={120}></img>
      //               </li>
      //       </ul>
      //
      // </div>

  )
}

export default Logo
