import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from "react-svg"
import logoImg from '../../public/logo.png';
import logoTextImg from '../assets/logo-text.png';
import menuSVG from '../assets/menu.svg';
import tagline from "../assets/tagline.svg"
import Logo from './Logo/logo';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

//   return (
//     <div className='relative'>
//       <div className="flex justify-between items-center py-8 px-8">
//         <Link  to={'/'} className='grid grid-cols-12 items-center  ' >
//           <Logo scale={3} classNames="scale-[0.3] col-span-4"/>
//           <div className='col-span-8 pt-2 uppercase text-[1.8rem] flex flex-col gap-2'>Workverse
//             {/*<br/>*/}
//             {/*<img src={tagline} className='tagline-text' alt=""/>*/}
//           </div>
//         </Link>
//         <div className='flex gap-8 '>
//           <Link  to={'/programs'} className="hover:border-b border-white">Our Program</Link>
//           <Link  to={'/for-employers'}  className="hover:border-b border-white">For Employers</Link>
//           <Link  to={'/wip'} className="hover:border-b border-white">WIP</Link>
//           <Link  to={'/whitepapers'} className="hover:border-b border-white">White Papers</Link>
//           <Link  to={'/about'} className="hover:border-b border-white">About</Link>
//           <Link  to={'/careers'} className="hover:border-b border-white">Careers</Link>
    return (
      <div className='relative' id="main-header">
        <div className="flex w-full justify-between relative container mx-auto lg:px-2 py-8 z-[111]">
          <Link to={'/'} className='grid grid-cols-12 items-center' >
            <Logo scale={3} classNames="scale-[0.3] col-span-4"/>
            <div className='col-span-8  uppercase text-[1.8rem] flex flex-col gap-2 mt-1'>Workverse
              {/*<img src={tagline} className='tagline-text' alt=""/>*/}
            </div>
          </Link>
          <div id="nav" className='hidden lg:flex gap-8 mt-4'>
            {/* <Link to={'/our-programs'} className="hover:border-b border-white">Programs</Link>
            <Link to={'/for-employers'}  className="hover:border-b border-white">For Employers</Link>
            <Link to={'/wip'} className="hover:border-b border-white">WIP</Link>
            <Link to={'/white-papers'} className="hover:border-b border-white">White Papers</Link>
            <Link to={'/about'} className="hover:border-b border-white">About</Link>*/}
            <Link to={'/careers'} className="hover:border-b border-white">Careers</Link> 
            <Link to={'/privacy-policy'} className='hover:border-b border-white'>Privacy policy</Link>
          </div>
          <div className='flex ml-auto justify-center items-center lg:hidden' onClick={() => setOpenMenu(!openMenu)}>
            <ReactSVG src={menuSVG} />
          </div>
        </div>
        <div className={`absolute w-[90%] flex flex-col z-[9999] rounded-lg py-8 mx-[5%] justify-center items-center transition-all duration-500 bg-[#011C1B] ${openMenu ? 'scale-y-100' : 'scale-y-0'}`}>
          {/* <Link to={'/our-programs'} className='mx-4 text-[1.2rem] text-white'>Programs</Link>
          <Link to={'/for-employers'} className='mx-4 text-[1.2rem] text-white'>For Employers</Link>
          <Link to={'/wip'} className='mx-4 text-[1.2rem] text-white'>WIP</Link>
          <Link to={'/white-papers'} className='mx-4 text-[1.2rem] text-white'>Whiter Papers</Link>
          <Link to={'/about'} className='mx-4 text-[1.2rem] text-white'>About</Link>*/}
          <Link to={'/careers'} className='mx-4 text-[1.2rem] text-white'>Careers</Link> 
          <Link to={'/privacy-policy'} className='mx-4 text-[1.2rem] text-white'>Privacy policy</Link>
        </div>
      </div>
    )
}

export default Header
