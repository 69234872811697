import React, { useEffect, useRef } from 'react'
import story_left from '../assets/about/story-left.svg'
import story_right from '../assets/about/story-right.svg'
import Header from '../components/header';
import aimIcon from '../assets/about/aim-icon.png'
import rocketIcon from '../assets/about/rocket-icon.png'
import evolutionDesktop from '../assets/about/evolution-desktop.png'
import evolutionMobile from '../assets/about/evolution-mobile.png'
import ncat from '../assets/about/ncat.svg'
import { ourAdvisor,ourTeam } from '../data/about';
import { gsap } from 'gsap';
import TestimonialsCards from "../components/TestimonialsCards";


function About() {
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null);
  const div7Ref = useRef(null);
  const div8Ref = useRef(null);
  const div9Ref = useRef(null);
  const div10Ref = useRef(null);
  const div11Ref = useRef(null);
  const div12Ref = useRef(null);
  const div13Ref = useRef(null);
  const div14Ref = useRef(null);
  const div15Ref = useRef(null);
  const div16Ref = useRef(null);
  const div17Ref = useRef(null);
  const div18Ref = useRef(null);

  useEffect(() => {
    gsap.fromTo(div1Ref.current, {
      // scale:0,
      x: -300,
      opacity: 0,
    }, {
      scale: 1,
      x: 0,
      opacity: 1,
      duration: 1,
      ease: "power2.inout",
    });


    gsap.fromTo(div2Ref.current, {
      x: 300,
      opacity: 0
    }, {
      x: 0,
      opacity: 1,
      duration: 1,
      ease: "power2.inout",
    })


    gsap.fromTo(div3Ref.current, {
      scale: 0,
      // x:-300,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div3Ref.current,
        start: "start bottom",
        end: "top 50%"
      },
      scale: 1,
      // x:0,
      opacity: 1,
      duration: 1,
      ease: "power2.inout",
    });
    gsap.fromTo(div4Ref.current, {
      scale: 0,
      // x:-300,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div4Ref.current,
        start: "start bottom",
        end: "top 50%"
      },
      scale: 1,
      // x:0,
      opacity: 1,
      duration: 1,
      ease: "power2.inout",
    });

    gsap.fromTo(div5Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div5Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })



    gsap.fromTo(div6Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div6Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })



    gsap.fromTo(div7Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div7Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })



    gsap.fromTo(div8Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div8Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })



    gsap.fromTo(div9Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div9Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })



    gsap.fromTo(div10Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div10Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })



    gsap.fromTo(div11Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div11Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })


    gsap.fromTo(div12Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div12Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })


    gsap.fromTo(div13Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div13Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })


    gsap.fromTo(div14Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div14Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })


    gsap.fromTo(div15Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div15Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })


    gsap.fromTo(div16Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div16Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })


    // gsap.fromTo(div16,{
    //     opacity:0,
    //     y:100,
    // },{
    //     scrollTrigger:{
    //         trigger:div16,
    //         start:"top bottom",
    //         end:"bottom 50%"
    //     },
    //     stagger:1,
    //     y:0,
    //     delay:1,
    //     scale:1,
    //     duration:2,
    //     opacity:1,
    //     ease:"power2.out"
    // })


    gsap.fromTo(div17Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div17Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })


    gsap.fromTo(div18Ref.current, {
      scale: 0,
      opacity: 0,
    }, {
      scrollTrigger: {
        trigger: div18Ref.current,
        start: "top bottom",
        end: "bottom 50%",
        scrub: true,
      },
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    })
  })

  return (
    <div className='w-full gradient overflow-x-hidden'>
      <Header />
      <div className='mx-auto container' >
        {/*Story Section */}
        <section className='py-4 md:py-8 xl:py-12  w-full'>
          <div className="flex flex-col md:flex-row ">
            {/* <div className="bg-shadow"></div>
            <div className="bg-shadow-left"></div>
            <div className="bg-shadow-right"></div> */}
            <div className="flex flex-col md:flex-row gap-8">
              <div ref={div1Ref}>
                <img src={story_left} className="max-w-full height-auto " alt="Samyak story" />
                <p className='text-sm opacity-100 z-10 text-white text-center'>Ganesh is a 15 year old, studying in a school that was <br /> run by Workvers's founder, Samyak</p>
              </div>
              <div ref={div2Ref}>
                <img src={story_right} className="max-w-full h-auto" alt="Samyak story right" />
                <p className='text-sm text-center z-10 text-white'>Rahul was a fresher who joined Samyak's first start up</p>
              </div>
            </div>
          </div>
          <div ref={div3Ref} className="  text-center">
            <p className='text-white py-2 font-light text-base  xl:text-4xl mt-4'>The above 2 interactions are amongst many triggers that played a role in the founding of Workverse.</p>
          </div>
        </section>
        {/*What we are*/}
        <section className='py-4 md:py-8  xl:py-12  w-full'>
          <div ref={div4Ref} >
            <h2 className='text-center text-[#bafff7] mb-4 md:mb-8 xl:mb-8'>What we are</h2>
            <div className="text-center">
              <p className='text-center text-white text-base xl:text-4xl font-light '>Workverse is a virtual workplace environment where graduates master 21st century soft skills and contextualize their innate intelligence for applications at work.</p>
            </div>
          </div>
        </section>
        {/*OurMission*/}
        <section className='py-4 md:py-8  xl:py-12  w-full'>
          <div ref={div5Ref} className="flex justify-center items-center">
            <img src={aimIcon} alt="Aim" className='max-w-full h-auto' />
          </div>
          <div ref={div6Ref} >
            <h2 className='text-center text-[#bafff7] mb-4 md:mb-8 xl:mb-8'>Our Mission</h2>
            <div className="text-center">
              <p className='text-center text-white font-light text-base  xl:text-4xl'>
                To ensure every graduate has the necessary exposure to train themselves to start thinking where A.I stops
              </p>
            </div>
          </div>
        </section>
        {/*Our Vision*/}
        <section className='py-4 md:py-8 xl:py-12 w-full'>
          <div ref={div7Ref} className="flex items-center justify-center">
            <img src={rocketIcon} alt="Vission Icon" className='max-w-full ' />
          </div>
          <div ref={div8Ref}>
            <h2 className='text-center text-[#bafff7] mb-4 md:mb-8 xl:mb-8'>Our Vision</h2>
            <div className="text-cener">
              <p className='text-center text-white font-light text-base xl:text-4xl'>
                To build future ready smart generalists for the 5th industrial revolution
              </p>
            </div>
          </div>
        </section>
        {/*Our Purpose*/}
        <section className='py-4 md:py-8  xl:py-12  w-full'>
          <div ref={div9Ref}>
            <h2 className='text-center text-[#bafff7] mb-2'>Our Purpose</h2>
            <div className="text-center">
              <p className='text-center text-white font-light text-base xl:text-4xl'>
                The popular perception is that education is about ‘attaining knowledge’ and an intelligent person therefore means who can meticulously apply that information to draw conclusions. With the emergence of artificial intelligence, the value of knowledge driven intelligence has declined. The new world of work will need professionals who can solve, create & communicate in ‘uncertainty’ and at the intersection of emotions, purpose/motivator and personalized context.
              </p>
            </div>
          </div>
          <div ref={div10Ref} className="text-center mt-4" >
            <p className='text-center text-white font-light text-base xl:text-4xl'>
              However our conventional education system does not train human brains to remain ‘Smarter’ than A.I.  <span className='text-[#bafff7] font-bold'>That's why we exist!</span>
            </p>
          </div>
        </section>
        {/*Our Evolution*/}
        <section className='py-4 md:py-8  xl:py-12 w-full'>
          <div className="row pt-5">
            <div ref={div11Ref} className="col-md-12">
              <h2 className='text-center  text-[#bafff7] mb-4 md:mb-8'>Our Evolution</h2>
            </div>
            <div  className="flex justify-center">
              <img src={evolutionDesktop} className="max-w-full h-auto hidden md:block" alt="workverse " />
              <img src={evolutionMobile} className="max-w-full h-auto block md:hidden" alt="workverse " />
            </div>
          </div>
        </section>
        {/*Our Impact*/}
        <section className='py-8 md:py-8  xl:py-12 w-full'>
          <div ref={div13Ref}>
            <h1 className='text-center text-[#bafff7] mb-4 md:mb-8 xl:mb-12'>Our Impact</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8  ">
            <div ref={div14Ref} className=" text-center mt-3">
              <p style={{textShadow:'0 0 3px #fffc'}} className='text-[#07edc4]'><b>1,000,000+</b></p>
              <p className='text-white font-light  '>Learners</p>
            </div>
            <div ref={div15Ref} className=" text-center mt-3">
              <p style={{textShadow:'0 0 3px #fffc'}} className='text-[#07edc4]'><b>40+</b></p>
              <p className='text-white fw-light glowyText'>Partner Institutions</p>
            </div>
            <div ref={div16Ref} className="text-center mt-3">
              <p style={{textShadow:'0 0 3px #fffc'}} className='text-[#07edc4]'><b>85%</b></p>
              <p className='text-white fw-light glowyText'>Placement Preference</p>
            </div>
          </div>
        </section>
        {/*Our Team*/}
        <section className='py-4 md:py-8 xl:py-12  w-full'>
          <div ref={div15Ref} >
            <h1 className='text-center text-[#bafff7] mb-4 md:mb-8 xl:mb-12'>Our Team</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-16  w-full ">
            {ourTeam.map((item, index) => (
              <div key={index} className=''>
                <div className="flex justify-center items-center">
                  <img src={item.img_src} alt="Team" className='w-auto h-80' />
                </div>
                <div className="team-details">
                  <p className='text-xs text-center text-[#07edc4] opacity-60 mb-2 '>{item.deg_name}</p>
                  {/*<p className='text-sm px-2 opacity-80 w-1/2 mx-auto md:w-full xl:w-3/5 '><i>{item.exp_text}</i></p>*/}
                </div>
              </div>
            ))}
          </div>
        </section>
        {/*Our Investor*/}
        <section className='py-4 md:py-8  xl:py-12 w-full'>
          <div ref={div16Ref} >
            <h1 className='text-center  text-[#bafff7] mb-4 md:mb-8 xl:mb-12'>Our Investor</h1>
          </div>
          <div ref={div17Ref} className="flex  justify-center items-center">
            <img src={ncat} alt="Team" className='max-w-full h-auto' />
          </div>
        </section>
        <section className='py-4 md:py-8'>
          <div ref={div18Ref} className="row pt-5">
            <h1 className='text-center text-[#bafff7] mb-4 md:mb-8 xl:mb-12'>Our Advisory Board</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4  gap-16 ">
            {ourAdvisor.map((item, index) => (
              <div key={index} className='' >
                <div className="flex justify-center items-center mb-2">
                  <img src={item.img_src} alt="Team" className='max-w-full h-auto' />
                </div>
                <div className="team-details">
                  {/*<p className='text-xs text-[#07edc4] text-center opacity-60'>{item.deg_name}</p>*/}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section ref={div14Ref} className='py-12 w-full'>
          <div className=" w-full">
            <TestimonialsCards />
          </div>
        </section>
        <section className='h-24'>
        </section>
      </div>
    </div>
  )
}

export default About
