import React from "react";

const FAQs = () => {
  return (
    <div className="container mx-auto pt-6 md:pt-12">
      <div
        id="page-header"
        className="flex flex-col md:flex-row justify-between   py-5  top-20 "
      >
        <div className="w-full md:w-1/2">
          <div className="text-[#31e2ed] mb-5 text-left">
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className="col-span-12 md:col-span-3 lg:col-span-6 text-right">
          <button
            style={{
              background:
                "linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)",
            }}
            className="shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white "
          >
            <div
              className="whitespace-nowrap"
              style={{
                border: "3px solid black",
                borderRadius: "4px",
                padding: "11px",
                paddingLeft: "25px",
                paddingRight: "25px",
                margin: "3px",
                marginRight: "-2px",
                marginLeft: "-1px",
                textShadow: " 0 0 10px #fff",
              }}
            >
              Apply Now {">>"}
            </div>
          </button>
        </div>
      </div>
      <h2 className="text-[#dbfdff] mb-4">Frequently Asked Questions</h2>
      <h3 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
        1. What are the fees and how is it structured ?
      </h3>
      <p className="text-[#DBFDFF] ml-[2vw] text-justify mb-8">
        The total fee is INR 9,999/- and the payment is structured in two parts:{" "}
        <br />
        INR 2,999/- to be paid upfront before the program begins <br />
        Remaining INR 7,000/- to be paid after recieving our placement
        support process and getting a job offer
      </p>
      <h3 className="font-Magistral font-bold text-[#31e2ed] mb-4">
        2. Who is eligible to join the fellowship ?
      </h3>
      <p className="text-[#DBFDFF] ml-[2vw] text-justify mb-8">
        The program is ideal for individuals in the final year of their
        undergraduate degree or with a work experience of up to 2 years. As of
        now, the learners must be based in Mumbai for the current batches.
      </p>
      <h3 className="font-Magistral font-bold text-[#31e2ed] mb-4">
        3. What is the application deadline ?
      </h3>
      <p className="text-[#DBFDFF] ml-[2vw] text-justify mb-8">
        Applications for any batch will close 5 days before its starting date.
        You can view the various batches and their starting dates by going to
        the 'Batch Selection' section in the application form
      </p>
     
        <h3 className="font-Magistral  font-bold text-[#31e2ed] mb-4">
          4. Do I need any prior knowledge, experience, or scores ?
        </h3>
        <p className="text-[#DBFDFF] ml-[2vw] text-justify mb-8">
          As this Fellowship is linked to placements, you are required to have
          scored at least 75% in your final year of college or diploma to
          fulfill employment requirements.
          <br />
          Other than that, we are looking for driven people such as those who
          have participated in extracurricular activities, held leadership
          positions, or have had any other experience that shows their
          motivation to do more than what is expected of them.
        </p>
     
      <h3 className="font-Magistral font-bold text-[#31e2ed] mb-4">
        5. Is internship experience counted as work experience ?
      </h3>
      <p className="text-[#DBFDFF] ml-[2vw] text-justify mb-8">
      When you go on the application form, you will find two separate categories, one for filling in details of your full-time job / freelancing work experience, and another for details about your internship experience. Both of them can be counted towards your work experience for eligibility into the fellowship.
.
      </p>
      <h3 className="font-Magistral font-bold text-[#31e2ed] mb-4">
        6. How will this fellowship help get me a job ?
      </h3>
      <p className="text-[#DBFDFF] ml-[2vw] text-justify">
        The program takes you through a simulated learning experience which not
        only teaches you soft skills, mental models and hacks to thrive in
        today’s workplace - but also provides exposure to mentoring sessions and
        masterclasses. After we help you prepare for placements, you’re all set
        to interview with our partner recruiters.
      </p>
     
      
      <div className="pb-12">
        <hr className="bg-[#BAFFF7] border-t-2 border-white mt-5" />
      </div>
    </div>
  );
};

export default FAQs;
