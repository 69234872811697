import React from "react";
import Arrow from "../../assets/Our Programs/arrow.png";
import calendar from "../../assets/Our Programs/Intro/Calendar.svg";
import clock from "../../assets/Our Programs/Intro/Clock.svg";
import connect from "../../assets/Our Programs/Intro/Connect.svg";
import search from "../../assets/Our Programs/Intro/Search.svg";
import JD from "../../assets/Our Programs/Intro/JD.png";
import quoteDesktop from "../../assets/Our Programs/Intro/desktop-quote.png";
import quoteMobile from "../../assets/Our Programs/Intro/mobile-quote.png";
import GradIntro from "../../assets/Our Programs/Intro/GradIntro.svg";
import RoboGirl from "../../assets/Our Programs/Intro/RoboGirl.svg";
import GridIntro from "../../assets/Our Programs/Intro/GridIntro.svg";
import quote2 from "../../assets/Our Programs/Intro/quote2.svg";
import mobJD from "../../assets/Our Programs/Intro/mob-JD.svg";
import mobQuote from "../../assets/Our Programs/Intro/mob-quote.svg";

function Intro({ handleNext }: { handleNext: (currTab: string) => void }) {
  return (
    <div className="container mx-auto pt-6 md:pt-12">
      <div
        id="page-header"
        className="flex flex-col md:flex-row justify-between   py-5  top-20 "
      >
        <div className="w-full md:w-1/2">
          <div className="text-[#31e2ed] mb-5 text-left">
            <h1>The Smart Fellowship for Finance & Commerce Professionals</h1>
          </div>
        </div>
        <div className="col-span-12 md:col-span-3 lg:col-span-6 text-right">
          <button
            style={{
              background:
                "linear-gradient(87.95deg, #00C1AE -8.31%, #0294FB 116.82%)",
            }}
            className="shadow-[0_0px_10px_rgba(2,148,251,1)] px-2 text-[14px] 2xl:text-[18px] rounded text-white "
          >
            <div
              className="whitespace-nowrap"
              style={{
                border: "3px solid black",
                borderRadius: "4px",
                padding: "11px",
                paddingLeft: "25px",
                paddingRight: "25px",
                margin: "3px",
                marginRight: "-2px",
                marginLeft: "-1px",
                textShadow: " 0 0 10px #fff",
              }}
            >
              Apply Now {">>"}
            </div>
          </button>
        </div>
      </div>
      <h1 className=" text-[#dbfdff] mb-4">
        <b>Accelerate Your Finance Career with the Smart Fellowship</b>
      </h1>
      <p className="text-[#dbfdff]  text-justify">
        The{" "}
        <span style={{ color: "#fff", fontWeight: "bold" }}>
          Smart Fellowship 
        </span>{" "}
        is an accelerator for your career growth. We are a roleplay and
        simulation based learning journey that trains your brain to start
        thinking where A.I stops!
        <br />
        <br />
        This experience empowers you to master soft skills, mental models and
        workplace hacks. These are superpowers that ensure you have an edge over
        artificial intelligence at work for the next 15 years.
      </p>
      <div className="grid grid-cols-12 gap-4  mt-24">
        <div className="col-span-12  md:col-span-3 sm:col-span-6 imgContIntro flex justify-center items-center mx-auto w-[75%] h-[75%]">
          <img src={calendar} className=" max-w-full h-auto" alt="" />
        </div>
        <div className="col-span-12 md:col-span-3 sm:col-span-6 imgContIntr flex justify-center items-center mx-auto  w-[75%] h-[75%]">
          <img src={clock} className="   max-w-full h-auto" alt="" />
        </div>
        <div className="col-span-12 md:col-span-3 sm:col-span-6 imgContIntr flex justify-center items-center mx-auto  w-[75%] h-[75%]">
          <img src={search} className=" max-w-full h-auto" alt="" />
        </div>
        <div className="col-span-12 md:col-span-3 sm:col-span-6 imgContIntr flex justify-center items-center mx-auto  w-[75%] h-[75%]">
          <img src={connect} className=" max-w-full h-auto" alt="" />
        </div>
      </div>
      <div className="grid  mx-5">
        <p className="text-[#dbfdff] textSchedule text-right text-[0.7em]">
          *Schedule designed to suit students and working professionals.
        </p>
      </div>
      <div className="mt-4  ">
        <h2 className="text-[#dbfdff] mb-4 ">
          <b>2 types of tasks you have to perform at work</b>
        </h2>

        <p className=" text-[#dbfdff]">
          In career domains like BFSI, Accounting and Statutory Compliance, the
          nature of tasks and challenges are divided into 2 broad buckets:
          Process-based & Thinking-based.
        </p>
      </div>
  
 
    
        <div className=" flex justify-center  items-center mt-5 mb-8">
          <img src={quoteDesktop} className="max-w-full h-auto  hidden md:block" alt="" />
          <img src={quoteMobile} className="max-w-full h-auto block md:hidden " alt="" />
        </div>
   
      
      <div className=" flex justify-center items-center  mt-5 mb-5 ">
        <img src={JD} className="w-full md:w-1/2 h-auto" alt="" />
      </div>
  
  
      <div className="grid grid-cols-12 mt-8 mb-8  ">
        <div className="col-span-12 lg:col-span-7 py-3">
          <p className=" text-[#dbfdff] p-[30px] text-center">
            Academics or competitive certifications like C.A/C.S train you to
            perform predictable processes (theoretical and technical) whereas
            real-life work experience prepares you for tackling the
            unpredictable (practical and real life!).
            <br />
            <br />
            This combination worked perfectly well since you were expected to
            take 1-3 years on the job to learn skills like critical thinking,
            problem solving, emotional intelligence, and creativity that help
            you 'think' out of the box.  But those days are gone. New age
            business models and technologies have disrupted the world of work.
            <br />
            <br />
            Today's recruiters expect much more from you, and only prefer hiring
            and promoting Smart professionals.
          </p>
        </div>
        <div className="col-span-12 lg:col-span-4 GradContIntro flex justify-center items-center w-[95%] h-[95%]">
          <img
            src={GradIntro}
            className="  img-fluid max-w-full h-auto"
            alt=""
          />
        </div>
      </div>
      <h2 className=" text-[#dbfdff]">
        <b>
          What you will have to do as process-driven tasks are being automated
        </b>
      </h2>
      <div
        className="grid grid-cols-12 max-md:px-5 mob-res  mt-5"
        style={{
          background: `url${GridIntro}`,
          backgroundSize: "cover",
          textAlign: "justify",
          minHeight: "300px",
        }}
      >
        <div className="col-span-12 md:col-span-4">
          <div className="mb-4">
            <h5 style={{ color: "#fff" }}>Entrepreneurial thinking</h5>
            <p className="text-[#dbfdff]">
              Implement an adaptive cash flow plan in context of external
              environment, CEO’s vision and realities of business
            </p>
          </div>
          <div className="mb-4">
            <h5 style={{ color: "#fff" }}>Subjective decision-making</h5>
            <p className="text-[#dbfdff]">
              Take bold decisions to continue expense heads if one “feels” they
              will eventually deliver tangible + intangible returns
            </p>
          </div>
          <div className="mb-4">
            <h5 style={{ color: "#fff" }}>Creative problem solving</h5>
            <p className="text-[#dbfdff]">
              Apply jugaad to achieve workarounds if set processes and rules are
              being an obstacle to solution
            </p>
          </div>
          <div className="mb-4">
            <h5 style={{ color: "#fff" }}>Emotional intelligence</h5>
            <p className="text-[#dbfdff]">
              Contextualize communication and tone  based on past and future
              potential of business relationships 
            </p>
          </div>
          <div className="mb-4">
            <h5 style={{ color: "#fff" }}>Adaptive approach</h5>
            <p className="text-[#dbfdff]">
              Plan who needs to do what based on capabilities, attitude and
              timelines 
            </p>
          </div>
        </div>
        <div className="col-span-12 md:col-span-5">
          <div className="RoboGirlContIntro">
            <img
              src={RoboGirl}
              className="flex justify-center items-center img-fluid w-full h-auto"
              alt=""
            />
          </div>
        </div>
        <div
          className="col-span-12 md:col-span-3 flex-1 flex flex-col justify-between"
          style={{ textAlign: "justify" }}
        >
          <div>
            <div className="mb-4">
              <h5 style={{ color: "#fff" }}>Numerical thinking</h5>
              <p className="text-[#dbfdff]">
                Objective calculation of monthly financials
              </p>
            </div>
            <div className="mb-4">
              <h5 style={{ color: "#fff" }}>Theoretical</h5>
              <p className="text-[#dbfdff]">
                Mathematically identify expenses that are not justified in
                context of revenue
              </p>
              <br />
            </div>
            <div className="mb-4">
              <h5 style={{ color: "#fff" }}>Process-Driven</h5>
              <p className="text-[#dbfdff]">
                Identify roadblocks and propose solutions based on known rules
                and processes
              </p>
              <br />
            </div>
            <div className="mb-3">
              <h5 style={{ color: "#fff" }}>Logical</h5>
              <p className="text-[#dbfdff]">
                Send communication to vendors and clients for payments,
                informing of delays and consequence etc 
              </p>
              <br />
            </div>
            <div className=" mb-4">
              <h5 style={{ color: "#fff" }}>Repetitive</h5>
              <p className="text-[#dbfdff]">
                Follow up with different stakeholders involved in a task 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center  mt-24">
        <div className="w-1/2 h-1/2">
          <img src={quote2} className=" w-full h-auto img-fluid" alt="" />
        </div>
      </div>
      <div className="grid grid-cols-12 mt-4  ">
        <div className="col-span-12 py-3">
          <p className="text-[#dbfdff] p-[30px] text-center">
            However this is actually great news and not something to be worried
            about. Let robots do all the repetitive and boring work! Being
            successful at performing higher order tasks from early on in your
            career empowers you to grow faster, earn more money, make new
            connections, and most importantly, enjoy the work you do!
            <br />
            <br />
            Apply to join the placement-linked Smart Fellowship where you are at
            the center of 100+ scenarios that you will experience in real work
            life. While tackling them in the simulation, you will learn mental
            models, workplace hacks, and develop wisdom that has contributed to
            the success of many top professionals.
            <br />
            <br />
            Get ready to jump start your career with a much greater foundation
            than others who come with only degrees and technical certifications.
          </p>
        </div>
      </div>
      <div className="pb-12">
        <hr className=" bg-[#BAFFF7] border-t-2 border-white mt-5" />
        <div className="grid grid-cols-12">
          <div className="md:col-span-9"></div>
          <div className="col-span-12 md:col-span-3 justify-end">
            <button
              className="flex gap-12 justify-center mt-4  py-4 w-full border-[0.5px] border-white bg-transparent rounded cursor-pointer ease-linear duration-300"
              onClick={(e) => handleNext("tab-2")}
              style={{ width: "100%", alignContent: "centre" }}
            >
              <div className="txt font-Magistral text-lg font-light text-center text-[#DBFDFF]">
                Next: What You Learn
              </div>
              <img
                src={Arrow}
                alt=""
                width={20}
                height={30}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
