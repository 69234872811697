import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BlueButton from "../Buttons/BlueButton";
import { toast } from "react-toastify";
import "./jobapply.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const JobApply = ({ open, setOpen, selectedJd }) => {
  const [value, setValue] = React.useState(0);
  const [checkBox, setCheckBox] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setValue(0);
    setCheckBox(false);
  };

  const handleChange = (event, newValue) => {
    if (!checkBox) {
      toast.error("Please read the description and check the box below");
      return;
    }
    setValue(newValue);
  };

  const handleNextButtonClick = () => {
    if (!checkBox) {
      toast.error("Please read the description and check the box below");
      return;
    }
    console.log(selectedJd);
    window.open(selectedJd?.googleFormLink, "_target");
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className="appbar-jobpost"
          sx={{ position: "relative", backgroundColor: "#6bbbbc" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              className="jobpost-main-header"
            >
              {selectedJd?.role}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          className="appbar-jobpost"
          sx={{ width: "100%", backgroundColor: "#1b6662" }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  fontWeight: 900,
                  color: "#fff !important",
                }}
                label="Job Description"
                {...a11yProps(0)}
                onClick={() => {
                  setCheckBox(false);
                }}
              />
            </Tabs>
          </Box>
          {/* Job Description */}
          <TabPanel value={value} index={0}>
            {/* Apply Form */}
            <Box className="jobpost-jd-container">
              <Box className="jobpost-jd-wrapper title-job-description">
                <p className="jobpost-jd-text">
                  You are requested to please read the following carefully
                  before applying to ensure the right fit for both of us!
                </p>
              </Box>
              <Box className="jobpost-jd-wrapper">
                <h5 className="jobpost-jd-header">
                  Role:{" "}
                  <span className="jobpost-jd-content">{selectedJd?.role}</span>
                </h5>
                <h5 className="jobpost-jd-header">
                  Compensation Range:{" "}
                  <span className="jobpost-jd-content">
                    {selectedJd?.compensationRange}
                  </span>
                </h5>
                <h5 className="jobpost-jd-header">
                  Location:{" "}
                  <span className="jobpost-jd-content">
                    {selectedJd?.location}
                  </span>
                </h5>
              </Box>

              <Box className="jobpost-jd-wrapper">
                <Box className="jobpost-jd-innerwrapper">
                  <p className="jobpost-jd-text-detail ">
                    <span className="jobpost-jd-header">About Workverse</span>
                    <br /> Formerly X Billion Skills Lab, Workverse is a virtual
                    world of work where youth master essential soft skills and
                    behavioral skills. Our mission is to empower young
                    professionals to thrive alongside artificial intelligence at
                    work, instead of being replaced by it. <br /> <br />
                    In the Workverse, learners “work” in an imaginary company
                    where they tackle 100s of business scenarios and compete to
                    become the CEO of the organization. Each of them have access
                    to a mentor “Neuroda” who is the world’s first a.i soft
                    skills coach. He guides them to make choices in the
                    simulation thereby fusing learning, assessment and practice.{" "}
                    <br /> <br />
                    In our past version (offline training based on the
                    principles of simulation and gamification) we have enabled
                    50,000+ learners to gain a unique edge in placements,
                    perform better than the average candidate at work, and build
                    automation-proof careers. <br />
                  </p>
                </Box>

                <Box className="jobpost-jd-innerwrapper">
                  <p className="jobpost-jd-text-detail ">
                    <span className="jobpost-jd-header">Founded In</span>
                    <br /> 2017
                  </p>
                </Box>

                <Box className="jobpost-jd-innerwrapper">
                  <p className="jobpost-jd-text-detail ">
                    <span className="jobpost-jd-header">
                      Learners positively impacted till date:
                    </span>
                    <br /> 50,000+
                  </p>
                </Box>

                <Box className="jobpost-jd-innerwrapper">
                  <p className="jobpost-jd-text-detail">
                    <span className="jobpost-jd-header">Founded by</span>
                    <br /> Samyak Chakrabarty (Listed by Forbes as one of Asia’s
                    top young entrepreneurs)
                  </p>
                </Box>

                <Box className="jobpost-jd-innerwrapper">
                  <p className="jobpost-jd-text-detail">
                    <span className="jobpost-jd-header">
                      
                    </span>
                    <br />
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/YLxI25HFEVQ"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </p>
                </Box>

                <Box className="jobpost-jd-innerwrapper">
                  <p className="jobpost-jd-text-detail">
                    <span className="jobpost-jd-header">
                      Media Links for reference
                    </span>{" "}
                    <br />
                    <a
                      href="https://www.instagram.com/p/C9fUN4dCS4o/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.instagram.com/p/C9fUN4dCS4o/
                    </a>
                    <br />
                    <br />
                    <a
                      href="https://yourstory.com/2021/11/billion-skills-lab-mumbai-startup-workplace-skills-smart-generalists-india"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://yourstory.com/2021/11/billion-skills-lab-mumbai-startup-workplace-skills-smart-generalists-india
                    </a>
                  </p>
                </Box>
              </Box>

              <Box className="jobpost-jd-wrapper">
                <h5 className="jobpost-jd-header ">
                  {/* About the {selectedJd?.department} department at Workverse */}
                  The most exciting part about this role:
                </h5>
                <p className="jobpost-jd-text">{selectedJd?.exitingPart}</p>
              </Box>

              <Box className="jobpost-jd-wrapper">
                <h5 className="jobpost-jd-header">Responsibilities:</h5>
                <ol className="jobpost-jd-text">
                  {selectedJd?.responsibilities?.map((responsibility) => (
                    <li>{responsibility}</li>
                  ))}
                </ol>
              </Box>

              <Box className="jobpost-jd-wrapper">
                <h5 className="jobpost-jd-header">Skills / Requirements:</h5>
                <ol className="jobpost-jd-text">
                  {selectedJd?.skillRequirements?.map((skillRequirement) => (
                    <li>{skillRequirement}</li>
                  ))}
                </ol>
              </Box>
              <Box className="jobpost-jd-wrapper">
                <h5 className="jobpost-jd-header">Join our team if...</h5>
                <ol className="jobpost-jd-text">
                  <li>
                    ● Double check if you are comfortable with the
                    work-from-office requirement
                    <br />● Share your CV with ayan@workverse.in, along with a
                    brief note about why you think this role was made for you!
                  </li>
                </ol>
              </Box>
              <br />
              <br />
              <p className="jobpost-jd-text jd-about">
                Please make sure to read the entire job description before
                proceeding
              </p>
              <hr />
              <Box className="jobpost-jd-wrapper">
                <input
                  type="checkbox"
                  id="checkbox"
                  onChange={() => setCheckBox(!checkBox)}
                />
                <label for="checkbox" className="jobpost-jd-text">
                  I have read above information
                </label>
              </Box>
            </Box>
            <Box sx={{ width: "150px", margin: "20px auto 0 auto" }}>
              <BlueButton text={"Next"} onClick={handleNextButtonClick} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}></TabPanel>
        </Box>
      </Dialog>
    </div>
  );
};

export default JobApply;
