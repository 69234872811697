import manish from '../assets/about/OurTeam/Manish 1.svg';
import samyak from '../assets/about/OurTeam/Samyak.svg';
import ayan from '../assets/about/OurTeam/Ayan.svg';
import ruchika from '../assets/about/OurTeam/Ruchika.svg';
import ashok from '../assets/about/OurTeam/Ashok.svg';
import ritika from '../assets/about/OurTeam/Ritika.svg';
import aditya from '../assets/about/OurTeam/Aditya 4.png';
import siya from '../assets/about/OurTeam/Siya.svg';
import sylwin from '../assets/about/OurTeam/Sylwin.svg';

import amit from '../assets/about/advisor/amit.svg';
import aparna from '../assets/about/advisor/aparna.svg';
import ashwani from '../assets/about/advisor/ashwani.svg';
import Lakshmi from '../assets/about/advisor/Lakshmi.svg';
import milind from '../assets/about/advisor/milind.svg';
import nikhil from '../assets/about/advisor/nikhil.svg';
import poonam from '../assets/about/advisor/poonam.svg';
import sandeep from '../assets/about/advisor/sandeep.svg';
import yashraj from '../assets/about/advisor/yashraj.svg';

import mukesh from '../assets/about/Testimonials/mukesh.svg'
import amisha from '../assets/about/Testimonials/amisha.svg'
import kashish from '../assets/about/Testimonials/kashish.svg'
import anubhav from '../assets/about/Testimonials/anubhav.svg'
import akhil from '../assets/about/Testimonials/akhil.svg'
const ourAdvisor = [
    {
        img_src: amit,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: aparna,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: ashwani,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: Lakshmi,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: milind,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: nikhil,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: poonam,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: sandeep,
        deg_name: "Trainer & Content Architect",

    },
    {
        img_src: yashraj,
        deg_name: "Trainer & Content Architect",

    },
]

const ourTeam = [
    {
        img_src: manish,
        deg_name: "Director",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: samyak,
        deg_name: "Managing Director",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: ayan,
        deg_name: "Chief Simulation Architect",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: ruchika,
        deg_name: "Chief Operations Architect",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: ashok,
        deg_name: "Trainer & Content Architect",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: ritika,
        deg_name: "Program Manager",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: aditya,
        deg_name: "Curriculum Design Architect",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: siya,
        deg_name: "Creative Generalist",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
    {
        img_src: sylwin,
        deg_name: "Visual Architect",
        exp_text: "6 Years Experience as a Lead Designer Leader for Change"
    },
]
const testimonials = [
    {
    img_src:mukesh,
    text:"Two years ago, I found a job vacancy, that required only graduates. Despite not having graduated, I got hired over other candidates through storytelling a skill that I learned through Workverse. Their program prioritizes personal as well as professional growth, which helped me develop confidence and become a better version of myself.",
    name:"Mukesh Tiwari",
    col_name:"Udaan India Foundation",
  },

    {
    img_src:amisha,
    text:"Workverse training emphasized the importance of non-technical skills in the workplace. It provided a balanced exposure to technical and interpersonal skills, teaching me effective communication, emotional management, and problem-solving. Now, I'm more confident and capable of handling various challenges, becoming a better version of myself.",
    name:"AMISHA GONSALVES",
    col_name:"Fr. Conceicao Rodrigues College of Engineering",
  },

    {
    img_src:kashish,
    text:"I'm immensely grateful to the Workverse team for an incredible learning experience. Since joining, my confidence has grown, my time management skills improved, and my professional relationships strengthened. The diverse and holistic curriculum facilitated my overall development, overcoming workplace challenges as a fresher",
    name:"KASHISH KHURANA",
    col_name:"Jagran Lakecity University",
  },

    {
    img_src:anubhav,
    text:"The Workverse program improved my professional and interpersonal skills comprehensively, from communication to task management. It enhanced my understanding of the corporate sector. Previously hesitant in approaching people, I now confidently engage and keep them interested. Being proactive at work has impressed my seniors.",
    name:"ANUBHAV GUPTA",
    col_name:"SRM College",
  },

    {
    img_src:akhil,
    text:"Workverse's Sept 2022 program taught me creative problem-solving through simple mental models. Increased productivity and better management of daily work issues are the valuable outcomes. Grateful to Workverse for the freshers' program that gave me a unique edge in my work.",
    name:"AKHIL RANA",
    col_name:"Government Polytechnic College, Hamirpur",
  },


];

export {
   ourTeam,testimonials,ourAdvisor
}
